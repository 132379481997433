import { LitElement, html, css } from "lit-element";
import { customElement, property, state } from "lit/decorators.js";

import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";

import "../../components/fm-dialog.js";

import { toast } from "../../utils.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";
import router from "../../router/index.js";

import {
	type TaxFiling,
	type TaxFilingStatus,
	indberetningstypeDescription,
	rettekodeDescription,
	statusCodeDescription,
} from "./tax-filing.js";

declare global {
	interface HTMLElementTagNameMap {
		"tax-filing-view": TaxFilingView;
	}
}

@customElement("tax-filing-view")
export class TaxFilingView extends LitElement {
	@property({ type: Number })
	filing_id: number | null = null;

	@state()
	private taxFilingStatuses: TaxFilingStatus[] = [];

	@state()
	taxFiling: TaxFiling | null = null;

	@state()
	showNewStatusDialog = false;

	@state()
	newStatus: {
		file: string | null;
		fileName: string | null;
		advisFile: string | null;
		advisFileName: string | null;
		fejlFile: string | null;
		fejlFileName: string | null;
	} = {
		file: null,
		fileName: null,
		advisFile: null,
		advisFileName: null,
		fejlFile: null,
		fejlFileName: null,
	};

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`
		.card-header .actions {
			display: flex;
			gap: 16px;
		}
		
		table tr th.title {
			color: #333;
			background-color: #f0f0f0;
		}

		table tr td {
			vertical-align: top;
		}

		table tr td pre {
			margin: 0px;
		}

		table tr td.nowrap {
			white-space: nowrap;
		}

		select[disabled] {
			background: #f0f0f0;
			color: #333;
		}
		
		.overlay #form {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.overlay #form label {
			margin-bottom: 4px;
		}

		.overlay #form div.row {
			display: flex;
			flex-direction: row;
			gap: 16px;
		}

		.overlay #form div.row > div {
			width: 100%;
		}

		.overlay div.buttons {
			justify-content: center;
		}
		
		.tiny-button {
			  min-width: 50px;
			  font-size:10px;
			  padding: 4px;
			}
		
		.delete {
			background-color: red;
		}
		`,
	];

	constructor() {
		super();
		this.resetNewStatus();
	}

	async connectedCallback() {
		super.connectedCallback();

		if (this.filing_id) {
			this.taxFiling = await this.getTaxFiling();
			this.taxFilingStatuses = await this.getTaxFilingStatuses();
		}
	}

	render() {
		return html`
			<div class="card">
				<div class="card-header">
					<div>
						<h1>${this.taxFiling?.name}</h1>
						<p>${this.taxFiling?.description}</p>
					</div>
					<div class="actions">
						<fm-button-v2 @click="${this.delete}" class="primary btn--small delete">Slet</fm-button-v2>
					</div>
				</div>
				
				<div class="card-header">
					<h2>Stamoplysninger</h2>
				</div>
				<table>
					<thead>
						<th>ID</th>
						<th></th>
						<th>Oprettet</th>
						<th>Individnr.</th>
						<th>Skatteår</th>
						<th>Indberetningstype</th>
						<th>Rettekode</th>
						<th>Individer</th>
					</thead>
					<tbody>
						<tr>
							<td>${this.taxFiling?.id}</td>
							<td>${
								this.taxFiling?.manual_filename
									? `Manuel (${this.taxFiling?.manual_filename})`
									: "Automatisk"
							}</td>
							<td>${
								this.taxFiling?.reg_date
									? DateFormatter.format(
											new Date(this.taxFiling?.reg_date),
											"dd-mm-yyyy HH:MM:ss",
										)
									: "Ukendt"
							}</td>
							<td>${this.taxFiling?.individnr}</td>
							<td>${this.taxFiling?.indkomstaar}</td>
							<td>${
								this.taxFiling?.individnr === "IFPA2235"
									? indberetningstypeDescription(
											this.taxFiling?.indberetningstype ?? null,
										)
									: ""
							}</td>
							<td>${rettekodeDescription(this.taxFiling?.rettekode ?? null)}</td>
							<td>${this.taxFiling?.entries}</td>
						</tr>
					</tbody>
				</table> 
			</div>

			<div class="card">
				<div class="card-header">
					<h2>Status</h2>
					<div class="actions">
						<fm-button-v2 @click="${this.openNewStatusDialog}" class="primary btn--small">Ny status</fm-button-v2>
					</div>
				</div>
				${this.renderTaxFilingStatuses()}
			</div>

			<fm-dialog
				.opened="${this.showNewStatusDialog}"
				@close="${() => {
					this.showNewStatusDialog = false;
				}}"
			>
			${
				this.showNewStatusDialog
					? html`
				<div class="overlay">
					<h1>Ny status</h1>
					<fm-form id="form">
						<div class="row">
							<label for="status-file" class="form-field">
								Statusfil (.xml)
           						<input type="file" accept=".xml" id="status-file" @change="${
												this.onStatusFileChange
											}" />
        					</label>
						</div>
						${
							this.showAdditionalFiles()
								? html`<div class="row">
							<label for="status-file" class="form-field">
								Advisfil (_ADVIS)
           						<input type="file" id="status-file" @change="${this.onAdvisFileChange}" />
        					</label>
						</div>
						<div class="row">
							<label for="status-file" class="form-field">
								Fejlfil (_FEJL)
           						<input type="file" id="status-file" @change="${this.onFejlFileChange}" />
        					</label>
						</div>`
								: null
						}
						<div class="row buttons">
							<fm-button-v2
							class="button"
							type="button"
							@click="${() => {
								this.showNewStatusDialog = false;
							}}"
							>
							Afbryd
							</fm-button-v2>
							<fm-button-v2
							id="submit-button"
							class="button"
							type="button"
							@click="${this.submitNewStatus}"
							>
							Gem
							</fm-button-v2>
						</div>
					</fm-form>
				</div>`
					: null
			}
			</fm-dialog>
		`;
	}

	async getTaxFiling(): Promise<TaxFiling | null> {
		return await api.get(`/tax-filings/${this.filing_id}`);
	}

	async getTaxFilingStatuses(): Promise<TaxFilingStatus[]> {
		return await api.get(`/tax-filings/${this.filing_id}/statuses`);
	}

	async downloadStatusFile(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));

		const status = this.taxFilingStatuses.find((status) => status.id === id);

		if (!status) {
			return;
		}

		const response = await api.get(
			`/tax-filings/${this.filing_id}/statuses/${id}/files/status`,
			true,
		);
		const url = URL.createObjectURL(await response.blob());
		const a = document.createElement("a");
		a.href = url;
		a.download = status.status_filename;
		a.click();
		URL.revokeObjectURL(url);
	}

	async downloadAdvis(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.downloadAdvisFile(id, false);
	}

	async downloadAdvisCsv(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.downloadAdvisFile(id, true);
	}

	async downloadAdvisFile(id: number, csv = false) {
		const fileExtension = csv ? ".csv" : "";
		const status = this.taxFilingStatuses.find((status) => status.id === id);

		if (!status || status.advis_filename === null) {
			return;
		}

		const response = await api.get(
			`/tax-filings/${this.filing_id}/statuses/${id}/files/advis?format=${
				csv ? "csv" : "ifpa"
			}`,
			true,
		);
		const url = URL.createObjectURL(await response.blob());
		const a = document.createElement("a");
		a.href = url;
		a.download = `${status.advis_filename}${fileExtension}`;
		a.click();
		URL.revokeObjectURL(url);
	}

	async downloadFejl(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.downloadFejlFile(id, false);
	}

	async downloadFejlCsv(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.downloadFejlFile(id, true);
	}

	async downloadFejlFile(id, csv = false) {
		const fileExtension = csv ? ".csv" : "";
		const status = this.taxFilingStatuses.find((status) => status.id === id);

		if (!status || status.fejl_filename === null) {
			return;
		}

		const response = await api.get(
			`/tax-filings/${this.filing_id}/statuses/${id}/files/fejl?format=${
				csv ? "csv" : "ifpa"
			}`,
			true,
		);
		const url = URL.createObjectURL(await response.blob());
		const a = document.createElement("a");
		a.href = url;
		a.download = `${status.fejl_filename}${fileExtension}`;
		a.click();
		URL.revokeObjectURL(url);
	}

	openNewStatusDialog() {
		this.resetNewStatus();
		this.showNewStatusDialog = true;
	}

	resetNewStatus() {
		this.newStatus = {
			file: null,
			fileName: null,
			advisFile: null,
			advisFileName: null,
			fejlFile: null,
			fejlFileName: null,
		};
	}

	renderTaxFilingStatuses() {
		return html`
			<table>
				<thead>
					<th>Tidspunkt</th>
					<th>Status</th>
					<th>Beskrivelse</th>
					<th>Download</th>
					<th>Slet</th>
				</thead>
				<tbody>
				${
					this.taxFilingStatuses.length > 0
						? this.taxFilingStatuses.map(
								(status) => html`
									<tr>
										<td class="nowrap">${DateFormatter.format(
											new Date(status.timestamp_ts),
											"dd-mm-yyyy HH:MM:ss",
										)}</td>
										<td class="nowrap">${statusCodeDescription(
											status.status_file_code,
										)}</td>
										<td><pre><strong>${
											status.status_filename
										}</strong></pre><br />${
											status.error_code === null
												? html`<pre>${status.status_code}</pre>`
												: html`<pre><strong>${status.error_code}</strong><br />${
														status.error_message?.replace("\r\n", "\n") ?? ""
													}</pre>`
										}</td>
										<td><a href="#" data-id="${status.id}" @click="${
											this.downloadStatusFile
										}">Status</a>${
											status.advis_filename !== null
												? html`<br /><a href="#" data-id="${status.id}" @click="${this.downloadAdvis}">Advis</a> | <a href="#" data-id="${status.id}" @click="${this.downloadAdvisCsv}">Advis (CSV)</a>`
												: null
										} ${
											status.fejl_filename !== null
												? html`<br /><a href="#" data-id="${status.id}" @click="${this.downloadFejl}">Fejl</a> | <a href="#" data-id="${status.id}" @click="${this.downloadFejlCsv}">Fejl (CSV)</a>`
												: null
										}
									</td>
									<td><fm-button-v2 type="button" class="button delete tiny-button" data-id="${
										status.id
									}" @click="${this.deleteStatus}">Slet</fm-button-v2></td>
									</tr>`,
							)
						: html`<tr><td colspan="5">Ingen status</td></tr>`
				}
				</tbody>
			</table>`;
	}

	// Show _ADVIS and _FEJL file inputs if the status file is an _3.xml file
	showAdditionalFiles() {
		return this.newStatus.fileName?.toLowerCase().endsWith("_3.xml") ?? false;
	}

	async onStatusFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		this.newStatus = {
			...this.newStatus,
			file: await this.readFile(file),
			fileName: file.name,
		};
	}

	async onAdvisFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		this.newStatus = {
			...this.newStatus,
			advisFile: await this.readFile(file),
			advisFileName: file.name,
		};
	}

	async onFejlFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		this.newStatus = {
			...this.newStatus,
			fejlFile: await this.readFile(file),
			fejlFileName: file.name,
		};
	}

	async submitNewStatus() {
		if (this.newStatus && !this.newStatus.file) {
			toast("Ingen statusfil valgt");
			return;
		}

		const submitButton: ButtonElementV2 | undefined | null =
			this.shadowRoot?.querySelector("#submit-button");

		if (!submitButton) {
			return;
		}

		submitButton.loading = true;

		try {
			const newStatus = await api.post(
				`/tax-filings/${this.filing_id}/statuses`,
				this.newStatus,
			);

			if (newStatus.status === "OK") {
				this.taxFiling = await this.getTaxFiling();
				this.taxFilingStatuses = await this.getTaxFilingStatuses();
				this.showNewStatusDialog = false;
			}

			toast("Saved");
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
		submitButton.loading = false;
	}

	readFile(f: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			// Resolve the promise with the file content on load
			reader.onload = () => {
				resolve(reader.result as string);
			};

			// Reject the promise on error
			reader.onerror = () => {
				reject(new Error("Error reading file"));
			};

			// Start reading the file
			reader.readAsText(f);
		});
	}

	async delete(_e) {
		if (!confirm("Er du sikker på at du vil slette denne indberetning?")) {
			return;
		}

		try {
			await api.delete(`/tax-filings/${this.filing_id}`);
			toast("Slettet");
			router.push("/tax/tax-filings");
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
	}

	async deleteStatus(e) {
		if (!confirm("Er du sikker på at du vil slette denne status?")) {
			return;
		}

		const id = e.target.getAttribute("data-id");

		try {
			await api.delete(`/tax-filings/${this.filing_id}/statuses/${id}`);
			this.taxFilingStatuses = await this.getTaxFilingStatuses();
			toast("Slettet");
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
	}
}
