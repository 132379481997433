import { LitElement, html } from "lit";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { sort } from "../../utils.js";

export default class LoginsListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			fromDate: {
				type: String,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
	}

	connectedCallback() {
		super.connectedCallback();
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>MitID Logins</h1>
        </div>
        <fm-form id="parms" class="form-grid" @submit="${this.refresh}">
           <div class="form-field">
             <label>Date<fm-date-range id="login" name="login"  names="logins-" ></fm-date-range></label>
            </div>
          <div class="form-field">
            <label>Search<input type="search"  name="query" value="${
							this.query || ""
						}" class="search-input" style="width:100%"></label>
          </div>
           <div class="form-field">
           <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
          </div>

       </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="created_at" @click="${this.sort}">Time</th>
              <th data-key="name" @click="${this.sort}">Person name</th>
              <th data-key="company_name" @click="${this.sort}">Company name</th>
              <th data-key="cpr" @click="${this.sort}">CPR/RID</th>
              <th data-key="cvr" @click="${this.sort}">CVR</th>
			  <th data-key="uuid" @click="${this.sort}">UUID</th>
              <th data-key="login_type" @click="${this.sort}">Type</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${DateFormatter.format(
									new Date(row.created_at),
									"dd-mm-yyyy HH:MM:ss",
								)}</td>
                <td>${row.name}</td>
                <td>${row.company_name}</td>
                <td>${row.rid || row.cpr}</td>
                <td>${row.cvr}</td>
                <td>${row.uuid}</td>
                <td>${this.renderLoginType(row.cpr, row.cvr, row.rid)}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	async refresh(_event) {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#login").value;
		const submitButton = this.shadowRoot.querySelector("#submit_button");
		localStorage.setItem("logins-fromdate", dates.from);
		localStorage.setItem("logins-todate", dates.to);
		submitButton.loading = true;
		this.rows = await api.get(
			`/users/logins?fromdate=${dates.from}&todate=${dates.to}&query=${parms.query}`,
		);
		submitButton.loading = false;
	}

	renderLoginType(cpr, cvr, rid) {
		if (rid) {
			return "Medarbejder";
			// biome-ignore lint/style/noUselessElse: <explanation>
		} else if (cvr) {
			return "Ledelsesrepræsentant";
			// biome-ignore lint/style/noUselessElse: <explanation>
		} else if (cpr) {
			return "Privatperson";
		}

		return "Unknown";
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}
}

customElements.define("logins-list-view", LoginsListView);
