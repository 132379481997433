import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { addDays, sort } from "../../utils.js";
import { date } from "../../formatting/dateformats.js";

export default class FundsSignaturesView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			document_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.doc = {};
		this.signtext = "";
		this.document_id = null;
		this.nota = this.nota.bind(this);
		this.refresh = this.refresh.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onClose = this.onClose.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -30));
		this.todate = date(today);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Erklæringer"));
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      .tiny-button {
          min-width: 50px;
          font-size:10px;
          padding: 4px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Signed Documents</h1>
        </div>
        <div class="card-block">
            <fm-form id="parms" class="reportform no-print" @submit="${
							this.refresh
						}">
            <div class="form-space">
              <label>From
                <input type="date"  class="date-input" name="fromdate" value="${
									this.fromdate
								}" >
              </label>
            </div>
            <div class="form-space">
              <label>To
                <input type="date"  class="date-input" name="todate" value="${
									this.todate
								}" >
              </label>
            </div>
            <div class="form-space">
            <label>Search
              <input type="search"  name="query"  class="search-input" >
            </label>
            </div>
             <div class="form-space">
                <fm-button id="submit_button" type="submit" class="button submit">Show</fm-button>
            </div>
            </fm-form>
        </div>
    </div>
    ${this.renderRows()}
    `;
	}

	renderRows() {
		if (this.rows.length === 0) {
			return "";
		}
		return html`
    <div class="card">
      <div class="card-block">
          <table>
              <thead>
                  <tr>
                      <th>Account Name</th>
                  </tr>
              </thead>
          ${this.rows.map((r) => this.renderRow(r))}
          </table>
      </div>
  </div>
   `;
	}

	renderShoplistItem(sli) {
		return html`
  <tr>
    <td>${sli.security}</td>
    <td>${sli.security_name}</td>
    <td>${sli.share}</td>
  </tr>`;
	}

	renderShoplist(c) {
		return html`
  <table>
  <tr>
    <th colspan="3">Shoppinglist</th>
  </tr>
  <tr>
    <th>Security</th>
  <th>Name</th>
  <th>share</th>
  </tr>
  ${(c.shoplist || []).map((sli) => this.renderShoplistItem(sli))}
  </table>`;
	}

	renderCustodyOwner(o) {
		return html`
    <tr>
      <td>${o.name}</td>
      <td>${o.email}</td>
      <td>${o.bind}</td>
    </tr>`;
	}

	renderCustodyOwners(c) {
		return html`
     <table>
     <tr>
     <th colspan="3">Owners</th>
     <tr>
     <th>Owner</th><th>Email</th><th>Bind</th>
     </tr>
     ${(c.owners || []).map((o) => this.renderCustodyOwner(o))}
    </table>`;
	}

	renderCustody(c) {
		return html`
  <tr>
  <td>${c.custody_code}</td>
  <td>${this.renderCustodyOwners(c)}</td>
  <td>${this.renderShoplist(c)}</td>
  </tr>  `;
	}

	renderSignature(s) {
		return html`
  <tr>
  <td>${s.user_name}</td>
  <td>${s.reg_date}</td>
  </tr>  `;
	}

	renderRelation(r) {
		return html`
  <tr>
  <td>${r.name1}</td>
  <td>${r.description}</td>
  <td>${r.name2}</td>
  </tr>  `;
	}

	renderRelations(a) {
		return html`
   <tr>
     <th>Name</th>
      <th>Is</th>
      <th>To</th>
    </tr> 
    ${(a.relations || []).map((r) => this.renderRelation(r))}
   </tr>`;
	}

	renderRow(r) {
		return html`
     <tr>
      <td style="background:grey" >${r.name}</td>
    </tr>
    <tr>
      <td> <table>  ${r.custodies.map((c) => this.renderCustody(c))} </table> </td>
    </tr>
    <tr>
    <td> 
      <table> 
      <tr>
      <td>Signatures</td>
        <td><table>
           ${r.signatures.map((s) => this.renderSignature(s))} 
           </table>
        </td>
      </tr>
      </table> 
      </td>
  </tr>
  <tr>
    <td>
      <table>
       <tr>
        <td>Relations</td>
        <td><table>
             ${this.renderRelations(r)}</td>
            </table>
            </td>
        </tr>
        </table>
     </td>
  </tr>`;
	}

	renderDocument(id) {
		if (id === this.document_id) {
			return html`
     <tr><td colspan="5"></td><td></td></tr>
     <tr>
      <td colspan="6">
       <pre>${this.signtext}</pre>
      </td> 
    </tr>
     <tr>
      <td colspan="6"> 
      <div> signed: ${this.doc.txt_reg_date} (GMT)</div>
        <div> signature id: ${this.doc.sign_id}</div>
        <div> signed by (pid): ${this.doc.sign_pid}</div>
        <div> signed by (name): ${this.doc.sign_name}</div>
        <div> Identity scheme: ${this.doc.identityscheme}</div>
        <div> Authentication type: ${this.doc.authenticationtype}</div>
      </td>
      </tr>
      <tr>
      <td colspan="4">
      ${this.renderAccount()}
      </td>
      </tr>
     `;
		}

		return "";
	}

	renderAccount() {
		return html`<fm-accountinfo document_id="${this.doc.document_id}" account_id="${this.doc.account_id}"></fm-accountinfo>`;
	}

	async search() {
		const form = this.shadowRoot.querySelector("#parms").value;
		this.fromdate = form.fromdate;
		this.todate = form.todate;
		this.rows = await api.get(
			`/documents?from=${this.fromdate}&to=${this.todate}&query=${form.query}`,
		);
	}

	async refresh() {
		this.shadowRoot.querySelector("#submit_button").loading = true;
		try {
			await this.search();
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
		this.shadowRoot.querySelector("#submit_button").loading = false;
	}

	async onShow(event) {
		const target = event.target;
		const document_id = target.getAttribute("data-id");
		this.doc = await api.get(`/documents/${document_id}`);
		this.signtext = this.doc.signtext;
		this.document_id = Number(document_id);
	}

	async onClose() {
		this.document_id = null;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}

	async nota(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const PDF = await API.get(`/nota/${id}/signed`);
		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF}`);
		a.setAttribute("download", "fm_signed_doc.pdf");
		a.click();
		target.loading = false;
	}
}

customElements.define("funds-signatures-view", FundsSignaturesView);
