import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

import { toast } from "../../utils.js";

export default class SignDocumentsView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			file_id: {
				type: String,
			},
			sign_id: {
				type: String,
			},
			signobj: {
				type: Object,
			},
			signing: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.onNew = this.onNew.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onError = this.onError.bind(this);
		this.onResponse = this.onResponse.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.refresh = this.refresh.bind(this);
		this.file_id = null;
		this.sign_id = null;
		this.signobj = {};
		this.signing = false;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.rows = await api.get("/docs");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>System Files</h2>
          <fm-button type="button" @click="${this.refresh}">Refresh</fm-button>
          <fm-button type="button" @click="${this.onNew}">New</fm-button>
        </div>
    </div>

      ${this.renderFileForm()}
        <table>
          <thead>
            <tr>
            <th></th>
            <th>Code</th>
             <th>Intro</th>
             <th>Header</th>
             <th>Mime Type</th>
             <th>Encoding</th>
             <th>Parms</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>
                   <button type="button" data-id="${row.id}" @click="${this.onEdit}">Edit</button>
                </td>
                <td>${row.code}</td>
                <td>${row.introtext}</td>
                <td>${row.headertext}</td>
                <td>${row.mime_type}</td>
                <td>${row.encoding}</td>
                <td>${row.parms}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>`;
	}

	renderFileForm() {
		if (this.file_id === "0") {
			return this.renderNewForm();
		}
		if (this.file_id) {
			return this.renderEditForm();
		}
		if (this.signing && this.signobj.href) {
			return html`<iframe height="1000pt" width="100%" src="${this.signobj.href}"></iframe>`;
		}
		return "";
	}

	renderNewForm() {
		return html`
    <div class="card" style="border:1px solid black">
       <fm-form  method="post" url="/docs" class="form-grid" @error="${this.onError}" @response="${this.onResponse}">
       <input type="hidden" id="mime_type" name="mime_type" />
       <input type="hidden" id="txtcontent" name="txtcontent" />
       <input type="hidden" id="encoding" name="encoding" />
       <div class="form-field">
       <label>Code
          <input type="text" name="code" />
        </label>
        </div>
        <div class="form-field">
       <label>Header
          <input type="text" name="headertext" />
        </label>
        </div>
        <div class="form-field">
        <label>Intro
          <input type="text" name="introtext" />
        </label>
        </div>
        <div class="form-field">
        <label class="form-field"> File
           <input type="file" id="file" @change="${this.onFileChange}"/>
        </label>
        </div>
        <div class="form-field">
         <fm-button type="submit">Save</fm-button>
         </div>
       </fm-form>
     </div>`;
	}

	renderEditForm() {
		return html`
    <div class="card" style="border:1px solid black">
       <fm-form  method="put" url="/docs/${
					this.row.id
				}" class="form-grid" @error="${this.onError}" @response="${
					this.onResponse
				}">
       <input type="hidden" name="id" value="${this.row.id}" />
       <input type="hidden" id="mime_type" name="mime_type" value="${
					this.row.mime_type || ""
				}"/>
       <input type="hidden" id="txtcontent" name="txtcontent" value="${
					this.row.txtcontent || ""
				}"/>
       <input type="hidden" name="encoding" id="encoding" value="${
					this.row.encoding || ""
				}"/>
       <div class="form-field">
       <label>Code
          <input type="text" name="code" value="${this.row.code || ""}"/>
        </label>
        </div>
        <div class="form-field">
       <label>Header
          <input type="text" name="headertext" value="${
						this.row.headertext || ""
					}"/>
        </label>
        </div>
        <div class="form-field">
        <label>Intro
          <input type="text" name="introtext" value="${this.row.introtext || ""}" />
        </label>
        </div>
        <div class="form-field">
        <label class="form-field"> File
           <input  type="file"  id="file"  @change="${this.onFileChange}"/>
        </label>
        </div>
        <div class="form-field">
         <fm-button type="submit">Save</fm-button>
         </div>
         <table style="width:100%">
          <tr>
            <td style="vertical-align:top">${this.renderRelations(
							this.row.relations || [],
						)}</td>
            <td>
              <object width="480" height="480" type="${
								this.row.mime_type
							}" data="${api.baseURL}/files/d/${this.row.id}?t=${api.token}"></object>
            </td>
          </tr>
        </table>
       </fm-form>
     </div>`;
	}

	renderRelations(rs) {
		if (this.action === "NEWREL") {
			return html``;
		}

		return html`
			<table>
			<tr><th>Security</th><th>Relation</th></tr>
			${rs.map(
				(r) => html`<tr><td>${r.name}</td><td>${r.relation_code}</td></tr>`,
			)}
		</table>`;
	}

	fileRead(f) {
		return new Promise((resolve, _reject) => {
			const reader = new FileReader();
			reader.addEventListener("loadend", (e) => {
				const f = this.shadowRoot.querySelector("#txtcontent");
				f.value = e.srcElement.result;
				return resolve("");
			});
			reader.readAsDataURL(f);
		});
	}

	async onFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];
		const f = this.shadowRoot.querySelector("#mime_type");
		const e = this.shadowRoot.querySelector("#encoding");
		f.value = file.type;
		if (file.type === "application/pdf") {
			e.value = "base64";
		} else {
			e.value = "";
		}
		await this.fileRead(file);
	}

	async refresh() {
		this.file_id = null;
		this.sign_id = null;
		this.signing = false;
		this.rows = await api.get("/docs");
	}

	onError(_event) {
		toast("Unable to save");
	}

	async onResponse(_event) {
		toast("Saved");
		this.rows = await api.get("/docs");
	}

	async onEdit(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.row = await api.get(`/docs/${id}`);
		this.file_id = id;
	}

	async onSign(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.sign_id = id;
	}

	onNew() {
		this.file_id = "0";
	}
}

customElements.define("sign-documents-view", SignDocumentsView);
