import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date, DateFormatter } from "../../formatting/dateformats.js";
import { money } from "../../formatting/numberformats.js";
import { addDays, toCsvF, toast } from "../../utils.js";

export default class AdvisorOrderView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <slot></slot>
      <div class="card">
        <div class="card-header">
          <h1>Fee Orders</h1>
          <router-link  class="btn btn--light" to="/advisors/neworders">
          <a href="/advisors/neworders">New</a>
          </router-link>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}" >
        <div class="form-field">
        <label>Created
          <fm-date-range id="daterange" name="fee"  names="fee-" ></fm-date-range>
         </label>
       </div>
       <div class="form-field"></div>
          <div class="form-field">
            <label>search
            <input type="search"  name="query"  class="search-input">
          </label>
          </div>
          <fm-button type="submit" id="submit_button">Refresh</fm-button>
          <fm-button type="button" id="download_button" class="btn" @click="${
						this.download
					}" style="max-width:80px">Download</fm-button>
        </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="advisor" @click="${this.sort}">Advisor</th>
              <th data-key="owner" @click="${this.sort}">Owner</th>
              <th data-key="custody_code" @click="${this.sort}">Custody</th>
              <th data-key="tradedate" @click="${this.sort}">Tradedate</th>
              <th data-key="security_name" @click="${this.sort}">Security</th>
              <th class="numeric" data-key="secvalue" @click="${this.sort}">Value d-1</th>
              <th class="numeric" data-key="amount" @click="${this.sort}">Amount</th>
              <th class="numeric" data-key="pct" @click="${this.sort}">Pro Anno</th>
              <th class="numeric" data-key="double_count" @click="${this.sort}">Double</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.advisor}</td>
                <td>${row.owner}</td>
                <td>${row.custody_code}</td>
                <td>${row.tradedate}</td>
                <td>${row.security_name}</td>
                <td class="numeric">${money(row.secvalue)}</td>
                <td class="numeric">${money(row.amountqc)}</td>
                <td class="numeric">${money(row.pct)}</td>
                <td class="numeric">${money(row.double_count)}</td>
              </tr>
                `,
						)}
          </tbody>
          <tfoot>
          <tr>
            <td colspan="5">Total</td>
            <td class="numeric">${money(this.totals.secvalue)}</td>
            <td class="numeric">${money(this.totals.amountqc)}</td>
            <td></td>
          </tr>
          </tfoot>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -5));
		this.todate = date(today);
		this.totals = { secvalue: 0, amountqc: 0 };
	}

	connectedCallback() {
		super.connectedCallback();

		let todate = localStorage.getItem("fee-todate");
		const fromdate = localStorage.getItem("fee-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("fee-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("fee-fromdate", todate);
		}
	}

	async fetch() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#daterange").value;
		localStorage.setItem("fee-fromdate", dates.from);
		localStorage.setItem("fee-todate", dates.to);

		try {
			this.rows = await api.get(
				`/advisors/orders?from=${dates.from}&to=${dates.to}&query=${parms.query}`,
			);
			const reducer = (acc, row) => ({
				secvalue: acc.secvalue + row.secvalue,
				amountqc: acc.amountqc + row.amountqc,
			});
			this.totals = this.rows.reduce(reducer, { secvalue: 0, amountqc: 0 });
		} catch (err) {
			Sentry.captureException(err);
			const json = await err.json();
			console.log(json);
			const txt_err = `Fejl: ${json.message || "(Ingen fejlmeddelelse)"}`;
			toast(txt_err);
		}
	}

	async refresh() {
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		await this.fetch();
		button.loading = false;
	}

	async download(_event) {
		const download_button = this.shadowRoot.querySelector("#download_button");
		try {
			download_button.loading = true;
			await this.fetch();
			const csvHeaders = [
				"advisor",
				"owner",
				"custody_code",
				"tradedate",
				"security_name",
				"secvalue",
				"amountqc",
				"pct",
				"double_count",
			];

			const csvFields = csvHeaders;
			const csvFormats = ["t", "t", "t", "t", "t", "m", "m", "q", "m"];

			let txtdata = toCsvF(csvHeaders, csvFields, csvFormats, this.rows);

			try {
				txtdata = btoa(txtdata);
			} catch (err) {
				Sentry.captureException(err);
				txtdata = btoa(unescape(encodeURIComponent(txtdata)));
			}
			const a = document.createElement("a");
			a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
			a.setAttribute("download", "fm_feeorders.csv");
			a.click();
			download_button.loading = false;
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(err);
			download_button.loading = false;
		}
	}
}

customElements.define("advisor-orders-view", AdvisorOrderView);
