import { html } from "lit";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";

export function ext_render(classobj) {
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Account return</h1>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${classobj.refresh}">
            <div class="form-field">
                <label>Account
                    <fm-autocomplete 
                        id="account_id" 
                        name="account_id"
                        url="/lov/accounts"
                        .rawvalue="${{
													id: classobj.account_id || "",
													name: classobj.account_name || "",
												}}"
                        select="id"
                        display="name"
                        @select="${classobj.accountChange}"
                    >
                    </fm-autocomplete>
                </label>
            </div>
    <div class="form-field">
           <label>Return Period
             <fm-date-range id="return" name="return"  names="return-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field">
           <label>Custody
             <select name="custody_id">
              <option value="">All</option>
              ${classobj.custodies.map(
								(c) =>
									html`<option value="${c.id}">${c.custody_code} ${c.name} ${c.active_yn}</option>`,
							)}
            </select> 
          </label>
         </div>
        <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
        <fm-button type="button" class="btn" style="max-width:80px" @click="${
					classobj.download
				}">Download</fm-button>
        </fm-form>
    </div>
     ${renderCustodies(classobj.rows)}
    `;
}

function renderCustodies(cs) {
	return html` ${cs.map((c) => renderCustody(c))} `;
}

function renderCustody(c) {
	let sum_r = 0;
	let sum_u = 0;
	let sum_d = 0;
	let sum_t = 0;
	let sum_f = 0;
	let sum_bs = 0;
	let sum_pv = 0;
	let sum_uv = 0;
	return html`
    <div class="card-block">
    <table>
         <tr>
            <th colspan="8">${c.custody_code} ${c.custody_name} ${
							c.custodytype_code
						} </th>
          </tr>
          <tr>
            <th>ISIN</th>
            <th>Inv.bevis</th>
            <th class="numeric">Værdi, primo</th>
            <th class="numeric">Køb/Salg</th>
            <th class="numeric">Urealiseret<br>Gevinst/tab</th>
            <th class="numeric">Realiseret<br>Gevinst/tab</th>
            <th class="numeric">Udbytte</th>
            <th class="numeric">Afkast i %</th>
            <th class="numeric">Værdi, ultimo</th>
            <th class="numeric">Indre værdi</th>

        </tr>
         ${(c.custody_return || []).map((r) => {
						sum_r += r.r_profit_loss_pc;
						sum_u += r.u_marketvalue_pc - r.bookvalue_pc;
						sum_d += r.dividend_pc;
						sum_bs += r.buysell_pc;
						sum_pv += r.p_marketvalue_pc;
						sum_uv += r.u_marketvalue_pc;
						sum_t += r.tax_pc;
						sum_f += r.advisorfee_pc;
						return renderReturn(r);
					})}
         <tr>
        <th colspan="2">I alt ${c.custody_code} ${c.custody_name}</th>
        <th class="numeric">${money(sum_pv)}</th>
        <th class="numeric">${money(sum_bs)}</th>
        <th class="numeric">${money(sum_u)}</th>
        <th class="numeric">${money(sum_r)}</th>
        <th class="numeric">${money(sum_d)}</th>
        <th class="numeric">${money(100 * (c.custody_twr - 1))}</th>
        <th class="numeric">${money(sum_uv)}</th>
        <th></th>
        </tr> 
        </table>
        </div>
        <div class="card-block">
        <table class="small">
            <tr><th colspan="2">Omkostninger</th></tr>
            <tr><td>Rådgivningshonorar</td><td class="numeric">${money(-sum_f)}</td></tr>
            <tr><td>Udbytteskat</td><td class="numeric">${money(sum_t)}</td></tr>
            <tr><th>Omkostninger i alt</th><th class="numeric">${money(
							sum_t - sum_f,
						)}</th></tr>
        </table>
        </div>
        `;
}

function renderReturn(r) {
	return html`
     <tr>
        <td>${r.security}</td>
        <td>${r.security_name}</td>
        <td class="numeric">${money(r.p_marketvalue_pc)}</td>
        <td class="numeric">${money(r.buysell_pc)}</td>
        <td class="numeric">${money(r.u_marketvalue_pc - r.bookvalue_pc)}</td>
        <td class="numeric">${money(r.r_profit_loss_pc)}</td>
        <td class="numeric">${money(r.dividend_pc)}</td>
        <td class="numeric">${money(100 * (r.twr - 1))}</td>
        <td class="numeric">${money(r.u_marketvalue_pc)}</td>
        <td class="numeric">${money(r.price)}</td>
    </tr>`;
}
