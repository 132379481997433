import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";
import router from "../../router/index.js";

export default class AccountNewPOAView extends LitElement {
	static get properties() {
		return {
			access_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Give Power of Attorney (POA) To</h1>
        </div>
        <fm-form id="form" class="form-grid" url="/accounts" method="post">
        <label class="form-field">
            Account
            <fm-autocomplete name="account_id" url="/lov/accounts" display="name" select="id"></fm-autocomplete>
        </label> 
          <button type="button" class="btn btn--light" @click="${this.save}" >Save</button>
        </fm-form>
      </div>
    `;
	}

	constructor() {
		super();
		this.row = {};
		this.save = this.save.bind(this);
		this.rerender = 0;
	}

	async save(event) {
		event.preventDefault();
		const formdata = this.shadowRoot.querySelector("#form").value;

		try {
			await api.post(`/accounts/${this.access_id}/poa`, formdata);
			router.push(`/accounts/${this.access_id}`);
			toast("POA Given");
		} catch (err) {
			console.log(err);
			Sentry.captureException(err);
		}
	}
}

customElements.define("account-newpoa-view", AccountNewPOAView);
