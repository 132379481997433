import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { quantity, money } from "../../formatting/numberformats.js";

export default class ReportFundStatusView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		const todate = new Date();
		this.asof = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	connectedCallback() {
		super.connectedCallback();
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.from = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.to = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Fund Status</h2>
        </div>
        <fm-form id="parms" class="reportform no-print" >
          <div class="form-space">
            <label>As of date</label>
            <input type="date"  class="date-input" name="asof"  value="${
							this.asof
						}" >
          </div>
          <div class="form-space">
          <fm-button id="submit_button" class="btn submit" @click="${
						this.refresh
					}">Refresh</fm-button>
        </div>
      </fm-form>
      </div>
        <table>
      <thead>
        <tr>
          <th>Fund</th>
          <th>Name</th>
          <th class="numeric">Quantity</th>
          <th class="numeric">Market value</th>
        </tr>
      </thead>
      <tbody>
      ${this.renderRows()}
      </tbody>
      <tfoot>
      <tr>
      <th></th><th></th>
      <th class="numeric">${quantity(this.sumQuantity)}</th>
      <th class="numeric">${money(this.sumValue)}</th>
      </tr>
      </tfoot>
      </table>
    `;
	}

	renderRows() {
		this.sumQuantity = 0;
		this.sumValue = 0;
		return html`${this.rows.map((r) => html`${this.renderRow(r)}`)}`;
	}

	renderRow(r) {
		this.sumQuantity += r.quantity;
		this.sumValue += r.marketvalue_pc;
		return html`
        <tr>
        <td>${r.security}</td>
        <td>${r.name}</td>
        <td class="numeric">${quantity(r.quantity)}</td>
        <td class="numeric">${money(r.marketvalue_pc)}</td>
    </tr>`;
	}

	async onChange() {
		const asof = this.asof;
		this.rows = await api.get(`/reports/fundstatus?asof=${asof}`);
	}

	async refresh() {
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		const params = this.shadowRoot.querySelector("#parms").value;
		this.asof = params.asof;
		await this.onChange();
		button.loading = false;
	}
}

customElements.define("report-fundstatus-view", ReportFundStatusView);
