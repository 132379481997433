import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import * as Sentry from "@sentry/browser";

import { get } from "../../api/client.js";
import { date } from "../../formatting/dateformats.js";
import { toCsv } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"sh-transaction-list-view": TransactionListView;
	}
}

type Transaction = {
	fund_name: string;
	subfund_name: string;
	custodian_name: string;
	investor_name: string;
	custodynumber: string;
	value_date: string;
	quantity: number;
	position: number;
};

@customElement("sh-transaction-list-view")
export class TransactionListView extends LitElement {
	@state()
	private transactions: Transaction[] = [];

	private sortedKey: keyof Transaction | null = null;

	@state()
	private fromdate: string = date(new Date());

	@state()
	private todate: string = date(new Date());

	@state()
	private query = "";

	@state()
	loading = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` :host {
			display: block;
			margin: 24px auto;
			max-width: 1400px;
		  }
		  th { cursor: pointer; } 
	  `,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Transactions</h1>
        </div>
        <div class="card-block">
         <input type="date"  class="date-input" id="fromdate" .value=${
						this.fromdate
					}
		  @change=${(e) => {
				this.fromdate = e.target.value;
			}}>
         <input type="date"  class="date-input" id="todate" .value=${
						this.todate
					}
		  @change=${(e) => {
				this.todate = e.target.value;
			}}>
          <input type="search" class="search-input" id="query" placeholder="Search" .value=${
						this.query
					}
		  @change=${(e) => {
				this.query = e.target.value;
			}}>
          <fm-button-v2 class="btn" @click="${this.refresh}" .loading="${
						this.loading
					}">Refresh</fm-button-v2>
          <fm-button-v2 type="button" id="download_button" class="btn" @click="${
						this.download
					}">Download</fm-button-v2>
        </div>
        <table>
          <thead>
            <tr>
              <th @click="${() => this.sortBy("fund_name")}">Fund</th>
              <th @click="${() => this.sortBy("subfund_name")}">Subfund</th>
              <th @click="${() => this.sortBy("value_date")}">Valuedate</th>
              <th @click="${() => this.sortBy("custodian_name")}">Custodian</th>
              <th @click="${() => this.sortBy("investor_name")}">Investor</th>
              <th @click="${() => this.sortBy("quantity")}" class="numeric">Quantity</th>
              <th @click="${() => this.sortBy("position")}" class="numeric">Position</th>
            </tr>
          </thead>
          <tbody>
            ${this.transactions.map(
							(transaction) => html`
              <tr>
                <td>${transaction.fund_name}</td>
                <td>${transaction.subfund_name}</td>
                <td>${transaction.value_date}</td>
                <td>${transaction.custodian_name}</td>
                <td>${transaction.investor_name}</td>
                <td class="numeric">${transaction.quantity}</td>
                <td class="numeric">${transaction.position}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.loading = true;
		await this.fetch({
			fromdate: this.fromdate,
			todate: this.todate,
			query: this.query,
		});
		this.loading = false;
	}

	async fetch(values: { fromdate: string; todate: string; query: string }) {
		const result = await get<{ data: Transaction[] }>(
			`/etfs/transactions?from=${values.fromdate}&to=${values.todate}&query=${values.query}`,
		);

		if (result.ok === false) {
			return;
		}

		this.transactions = result.value.data;
	}

	async refresh(_event) {
		this.loading = true;

		await this.fetch({
			fromdate: this.fromdate,
			todate: this.todate,
			query: this.query,
		});

		this.loading = false;
	}

	private sortBy(key: keyof Transaction): void {
		if (key === this.sortedKey) {
			this.transactions = this.transactions.slice(0).reverse();
			return;
		}

		this.sortedKey = key;
		this.transactions = this.transactions.slice(0).sort((a, b) => {
			const x = a[key];
			const y = b[key];
			if (typeof x === "string" && typeof y === "string") {
				return x.localeCompare(y);
			}

			if (typeof x === "number" && typeof y === "number") {
				return x - y;
			}

			return 0;
		});
	}

	async download() {
		const csvHeaders = [
			"fund_name",
			"subfund_name",
			"custodian_name",
			"custodynumber",
			"investor_name",
			"value_date",
			"quantity",
			"position",
		];

		const csvFields = [
			"fund_name",
			"subfund_name",
			"custodian_name",
			"custodynumber",
			"investor_name",
			"value_date",
			"quantity",
			"position",
		];

		let txtdata = toCsv(csvHeaders, csvFields, this.transactions);
		try {
			txtdata = btoa(txtdata);
		} catch (err) {
			Sentry.captureException(err);
			// remove n@ascii
			txtdata = btoa(unescape(encodeURIComponent(txtdata)));
		}
		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_transactions.csv");
		a.click();
	}
}
