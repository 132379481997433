import type { RouteOptions } from "runway";
import SignDocumentsView from "./sign-documents-view.js";
import SignOrdersView from "./sign-orders-view.js";
import SignView from "./sign-view.js";

export default {
	path: "signatures",
	component: SignView,
	children: [
		{
			path: "",
			exact: true,
			redirect: "documents",
		},
		{
			path: "documents",
			component: SignDocumentsView,
		},
		{
			path: "orders",
			component: SignOrdersView,
		},
	],
} satisfies RouteOptions;
