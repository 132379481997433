import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";

export default class QuestionEditView extends LitElement {
	static get properties() {
		return {
			question_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			answers: {
				type: Array,
			},
			categories: {
				type: Array,
			},
			newanswer: {
				type: Boolean,
			},
			answer_id: {
				type: String,
			},
			loading: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.row = {};
		this.answers = [];
		this.categories = [];
		this.groups = [];
		this.save = this.save.bind(this);
		this.refresh = this.refresh.bind(this);
		this.chkAnswer = this.chkAnswer.bind(this);
		this.editAnswer = this.editAnswer.bind(this);
		this.toggleDoc = this.toggleDoc.bind(this);
		this.newanswer = false;
		this.answer_id = null;
		this.loading = false;
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Question</h1>
        </div>
        <fm-form id = "info" class="form-grid" @submit="${this.save}">
          <input type="hidden" value="${this.question_id}">

          <div class="form-field">
            <label>Code
              <input type="text"  name="code" value="${this.row.code || ""}" >
            </label>
          </div>

          <div class="form-field">
            <label>Sort number
              <input type="text"  name="sortnumber" value="${
								this.row.sortnumber || ""
							}" >
            </label>
          </div>

          <div class="form-field">
            <label>Question
              <input type="text"  name="question" value="${
								this.row.question || ""
							}" >
            </label>
          </div>
          <div class="form-field">
            <label>Category
              <select name="category_id">
              ${this.categories.map(
								(c) =>
									html`<option value="${c.id}" ?selected=${
										c.id === this.row.category_id
									}>${c.description}</option>`,
							)}
              </select>
            </label>
            </div>
            <div class="form-field">
            <label>Group
              <select name="group_id">
              ${this.groups.map(
								(c) =>
									html`<option value="${c.id}" ?selected=${
										c.id === this.row.group_id
									}>${c.description}</option>`,
							)}
              </select>
            </label>
            <label>Required ? <input type="checkbox" ?checked="${
							this.row.required_yn === "Y"
						}"  name="required_yn"> </label>
            <label>Active ? <input type="checkbox" ?checked="${
							this.row.active_yn === "Y"
						}" name="active_yn"> </label>
            <label>
              Parms <input style="max-width:140px" type="text"  name="parms" value="${
								this.row.parms || ""
							}" >
            </label>
          </div>
          <div class="form-field">
            <label>Explanation
              <textarea rows="14" cols="60" name="explanation">${
								this.row.explanation || ""
							}</textarea>
            </label>
          </div>
          <div class="form-field">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Save</fm-button>
          </div>
          </fm-form>
          ${this.renderAnswers()}
      </div>
    `;
	}

	renderAnswers() {
		if (this.newanswer) {
			return html`<answer-edit-view @success="${this.refresh}" .question_id="${this.question_id}"></answer-edit-view>`;
		}
		if (this.answer_id) {
			return html`<answer-edit-view .answer_id="${this.answer_id}" .question_id="${this.question_id}" @success="${this.refresh}"></answer-edit-view>`;
		}
		return html`
   <table style="border:1px solid black">
   <thead>
   <tr>
     <th colspan="4" style="text-align:center"><h2>Answers</h2></th>
     <th>
       <router-link class="btn btn--light" to="/questions/${this.question_id}/newanswer">
         <a href="/questions/${this.question_id}/newanswer">New</a>
         </router-link>
     </th>
   </tr>
   <tr>
     <th>Sort</th>
     <th></th>
      <th>Code</th>
      <th>Answer</th>
      <th>Other (Y/N)</th>
      <th>Documentation</th>
     </tr>
   </thead>
   <tbody>
     ${(this.answers || []).map(
				(row) => html`
       <tr>
         <td><input type="text" data-id="${row.id}" value="${
						row.sortnumber || ""
					}" size="3" @change="${this.chkAnswer}" /></td>
         <td>
          <button type="button" data-id="${row.id}" @click="${this.editAnswer}">Edit</button> 
         </td>
         <td>${row.code}</td>
         <td>${row.answer}</td>
         <td>${row.other_yn}</td>
         <td><input type="checkbox" data-id="${row.id}" @change="${
						this.toggleDoc
					}" ?checked=${row.requiredocumentation}></td>
       </tr>
     `,
			)}
   </tbody>
 </table> `;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.loading = true;
		this.categories = await api.get(
			`/lov/qcategories?query=${this.question_id || ""}`,
		);
		this.groups = await api.get(`/lov/qgroups?query=${this.question_id || ""}`);

		if (this.question_id) {
			this.categories = await api.get(
				`/lov/qcategories?query=${this.question_id || ""}`,
			);
			this.row = await api.get(`/questions/${this.question_id}`);
			this.answers = await api.get(`/questions/${this.question_id}/answers`);
		}
		this.loading = false;
	}

	async chkAnswer(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const sort = target.value;
		const data = { answer_id: id, sortnumber: sort };
		try {
			this.answers = [];
			this.answers = await api.post(
				`/questions/${this.question_id}/answers`,
				data,
			);
		} catch (err) {
			Sentry.captureException(err);
			const e = await err.json();
			toast(JSON.stringify(e));
		}
	}

	async editAnswer(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.newanswer = false;
		this.answer_id = id;
	}

	async toggleDoc(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		try {
			const data = { requiredocumentation: target.checked };
			await api.put(`/questions/${this.question_id}/answers/${id}/doc`, data);
			toast("OK");
		} catch (err) {
			Sentry.captureException(err);
			const e = await err.json();
			toast(JSON.stringify(e));
		}
	}

	async refresh() {
		this.newanswer = false;
		this.answer_id = null;
		if (this.question_id) {
			this.categories = await api.get(
				`/lov/qcategories?query=${this.question_id || ""}`,
			);
			this.row = await api.get(`/questions/${this.question_id}`);
			this.answers = await api.get(`/questions/${this.question_id}/answers`);
		}
	}

	async save() {
		const f = this.shadowRoot.querySelector("#info").value;
		f.active_yn = f.active_yn ? "Y" : "N";
		f.required_yn = f.required_yn ? "Y" : "N";
		try {
			if (this.question_id) {
				await api.put(`/questions/${this.question_id}`, f);
				toast("Gemt");
			} else {
				await api.post("/questions", f);
				toast("Tilføjet");
			}
		} catch (err) {
			Sentry.captureException(err);
			const r = await err.json();
			toast(JSON.stringify(r));
		}
	}
}

customElements.define("question-edit-view", QuestionEditView);
