import { LitElement, html } from "lit";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class AccountListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			fromdate: {
				type: String,
			},
			rerender: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.rerender = 0;
		this.refresh = this.refresh.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("accounts-todate", todate);
		const fromdate = localStorage.getItem("accounts-fromdate");
		if (!fromdate) {
			localStorage.setItem("accounts-fromdate", todate);
		}
		this.query = localStorage.getItem("accounts-query");
	}

	connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("accounts-todate");
		const fromdate = localStorage.getItem("accounts-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("accounts-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("accounts-fromdate", todate);
		}
		this.query = localStorage.getItem("accounts-query");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Accounts</h1>
          <router-link class="btn btn--light" to="/accounts/new">
            <a href="/accounts/new"> New </a>
          </router-link>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
           <div class="form-field">
             <label>Created
               <fm-date-range id="created" name="created"  names="accounts-" ></fm-date-range>
              </label>
            </div>
          <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
          </div>
           <div class="form-field">
           <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
          </div>

       </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="name" @click="${this.sort}">Account name</th>
              <th data-key="account_type" @click="${this.sort}">Type</th>
              <th data-key="email" @click="${this.sort}">Email</th>
              <th data-key="companies" @click="${this.sort}">Companies</th>
              <th data-key="persons" @click="${this.sort}">Persons</th>
              <th data-key="active_yn" @click="${this.sort}">Active</th>
              <th data-key="warn_date" @click="${this.sort}">Warned</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td><router-link to="/accounts/${row.id}"><a>${
									row.name || "(none)"
								}</a></router-link></td>
                <td>${row.account_type}</td>
                <td>${row.email}</td>
                <td>${row.companies}</td>
                <td>${row.persons}</td>
                <td>${row.active_yn}</td>
                <td>${row.warn_date}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	async refresh(_event) {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("accounts-fromdate", dates.from);
		localStorage.setItem("accounts-todate", dates.to);
		localStorage.setItem("accounts-query", parms.query);
		this.query = parms.query;
		this.rows = await api.get(
			`/accounts?fromdate=${dates.from}&todate=${dates.to}&query=${parms.query}`,
		);
		submit_button.loading = false;
	}
}

customElements.define("account-list-view", AccountListView);
