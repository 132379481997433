import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";
import { renderAccountData } from "./account-edit-render.js";
import "../../components/fm-progress-dots.js";
import "../../components/fm-dialog.js";

export default class AccountEditView extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			returnrows: {
				type: Array,
			},
			company: {
				type: Object,
			},
			users: {
				type: Array,
			},
			poa: {
				type: Array,
			},
			custody_id: {
				type: Number,
			},
			newRelation: {
				type: Boolean,
			},
			signatures: {
				type: Array,
			},
			pepinfo: {
				type: Array,
			},
			loading: {
				type: Boolean,
			},
			accountData: {
				type: Object,
			},
			showAllPOAsReceived: {
				type: Boolean,
			},
			openDeactivateDialog: {
				type: Boolean,
			},
			sendDeactivateMail: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.account_id = "";
		this.row = {};
		this.returnrows = [];
		this.signatures = [];
		this.users = [];
		this.poa = [];
		this.custodies = [];
		this.relations = [];
		this.pepinfo = [];
		this.owners = [];
		this.save = this.save.bind(this);
		this.newCustody = this.newCustody.bind(this);
		this.editCustody = this.editCustody.bind(this);
		this.saveCustody = this.saveCustody.bind(this);
		this.saveNewCustody = this.saveNewCustody.bind(this);
		this.returnrefresh = this.returnrefresh.bind(this);
		this.returnPDF = this.returnPDF.bind(this);
		this.returnMail = this.returnMail.bind(this);
		this.backtoList = this.backtoList.bind(this);
		this.removePOA = this.removePOA.bind(this);
		this.accnumber = this.accnumber.bind(this);
		this.nota = this.nota.bind(this);
		this.onNewRelation = this.onNewRelation.bind(this);
		this.changePEPData = this.changePEPData.bind(this);
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.returnfrom = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.returnto = DateFormatter.format(todate, "yyyy-mm-dd");
		this.custody_id = null;
		this.custody = {};
		this.newRelation = false;
		this.loading = false;
		this.accountData = {};
		this.showAllPOAsReceived = false;
		this.openDeactivateDialog = false;
		this.sendDeactivateMail = true;
	}

	connectedCallback() {
		super.connectedCallback();
		this.loading = true;
		Promise.all([
			api.get(`/accounts/${this.account_id}/custodies`),
			api.get(`/accounts/${this.account_id}`),
			api.get(`/accounts/${this.account_id}/poa`),
			api.get(`/accounts/${this.account_id}/relations`),
			api.get(`/documents/accounts/${this.account_id}`),
			api.get(`/accounts/${this.account_id}/users`),
			api.get(`/accounts/${this.account_id}/pepinfo`),
			api.get(`/accounts/${this.account_id}/owners`),
		]).then((values) => {
			this.custodies = values[0];
			this.row = values[1];
			this.poa = values[2];
			this.relations = values[3];
			this.signatures = values[4];
			this.users = values[5];
			this.pepinfo = values[6];
			this.owners = values[7];
			this.loading = false;
		});
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.returnfrom = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.returnto = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	render() {
		const typ = {
			id: this.row.pmaccounttype_id,
			description: this.row.account_type,
		};
		return html`
    <style>
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }

      .loading-container {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
      }

      label.active_yn {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }

      label.active_yn button {
        margin: 0px 0px 8px 8px;
        max-width: 100px;
      }

      button.warning,
      fm-button-v2.warning {
        background: red;
      }

      .overlay #form {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .overlay #form label {
        margin-bottom: 4px;
      }

      .overlay #form div.row {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }

      .overlay #form div.row > div {
        width: 100%;
      }

      .overlay div.buttons {
        justify-content: center;
      }
      </style>

      ${this.renderPOAs()}
      ${this.renderLoading()}
      <div class="card">
        <div class="card-header">
          <h1>Account</h1>
        </div>
        <fm-form id="form" class="form-grid"  method="put">
          <label class="form-field">
          Account type
            <fm-autocomplete 
              name="pmaccounttype_id" 
              url="/lov/accounttypes" 
              display="description" 
              select="id" 
              value="${JSON.stringify(typ)}"></fm-autocomplete>
          </label>
          <label class="form-field">
            Account Name
            <input type="text" name="name" value="${this.row.name}" />
          </label>
          <label class="form-field">
            CVR
            <input type="text" name="cvr" value="${this.row.cvr}" />
          </label>
          <label class="form-field">
            E-mail
            <input type="text" name="email" value="${this.row.email || ""}" />
          </label>
          <label class="form-field">
            Phone
            <input type="text" name="phone" value="${this.row.phone || ""}" />
          </label>
          <label class="form-field active_yn">
              <div>
                Active (Y/N)
                <input readonly type="text" name="active_yn" value="${
									this.row.active_yn || ""
								}" />
              </div>
              ${
								this.row.active_yn === "Y"
									? html`
              <button class="btn btn--small warning" ?disabled="${
								this.row.active_yn === "N"
							}" @click="${() => {
								this.openDeactivateDialog = true;
							}}" >Deactivate</button>`
									: ""
							}
          </label>
          <label class="form-field">
            Warned Deactivation
            <input readonly type="text" name="warn_date" value="${
							this.row.warn_date || ""
						}" />
          </label>

          <button type="button" class="btn btn--light" @click="${this.save}" >Save</button>
        </fm-form>
      </div>

      <fm-dialog
        .opened="${this.openDeactivateDialog}"
        @close="${() => {
					this.openDeactivateDialog = false;
				}}"
      >
        ${
					this.openDeactivateDialog
						? html`
        <div class="overlay">
            <h1>Deactivate account</h1>
            <fm-form
              id="form"
            >
              <div class="row">
                Are you sure you want to deactivate this account?
              </div>
              <div class="row">
                <label for="send-deactivate-mail">
                <input type="checkbox" id="send-deactivate-mail" name="send-deactivate-mail" ?checked="${
									this.sendDeactivateMail
								}" @change="${(e) => {
									this.sendDeactivateMail = e.target.checked;
								}}"/> Send deactivation mail</label>
              </div>
              <div class="row buttons">
                <fm-button-v2
                  class="button"
                  type="button"
                  @click="${() => {
										this.openDeactivateDialog = false;
									}}"
                >
                  Cancel
                </fm-button-v2>
                <fm-button-v2
                  class="button warning"
                  type="button"
                  @click="${this.deactivateAccount}"
                >
                  Deactivate
                </fm-button-v2>
              </div>
            </fm-form>
          </div>`
						: null
				}
      </fm-dialog>
      ${this.renderUsers()}
      ${this.renderRelations()}
      ${this.renderPEPInfo()}
      ${this.renderCustodies()}
      ${this.renderSignatures()}
      ${this.renderData()}
    `;
	}

	renderLoading() {
		if (this.loading) {
			return html`
        <div class="loading-container">
          <fm-progress-dots></fm-progress-dots>
        </div>
      `;
		}

		return "";
	}

	renderUsers() {
		return html`
<div class="card">
<div class="card-header">
  <h2>Users</h2>
    <router-link class="btn btn--light" to="/accounts/${this.account_id}/newuser">New</router-link>
</div>
<table>
  <thead>
    <th>Name</th>
    <th>Type</th>
    <th>PID/RID</th>
    <th>Username</th>
    <th>CPR</th>
    <th>CVR</th>
    <th>Created</th>
    <th>Approved</th>
    <th>Files</th>
  </thead>
  ${this.users.map(
		(u) => html`
    <tr>
        <td><router-link to="/accounts/users/${u.id}"><a>${u.name}</a></router-link></td>
        <td>${u.type_code}</td>
        <td>${u.uniquename}</td>
        <td>${u.username}</td>
        <td>${u.cpr}</td>
        <td>${u.cvr}</td>
        <td>${u.txt_reg_date}</td>
        <td>${u.approved_yn}</td>
        <td>${u.file_count}</td>
    </tr>`,
	)}
</table>
</div>`;
	}

	renderUserList() {
		return html`
  <select id="userlist">
    ${(this.users || []).map((u) => {
			if (u.uniquename) {
				return html`<option value="${u.id}">${u.name}</option>`;
			}

			return "";
		})}
</select>
  `;
	}

	renderOwnerList() {
		return html`
  <select id="ownerlist">
    ${(this.owners || []).map((u) => {
			return html`<option value="${u.id}">${u.name}</option>`;
		})}
  </select>
  `;
	}

	renderRelations() {
		if (this.newRelation) {
			return this.renderRelation();
		}

		return html`
    <div class="card">
      <div class="card-header">
        <h2>Relations</h2>
        <fm-button type="button" @click="${this.onNewRelation}">New</fm-button>
      </div>
      <table>
        <thead>
          <th>Name</th>
          <th>CPR</th>
          <th>CVR</th>
          <th>is</th>
          <th>Code<th>
          <th>Val<th>
          <th>To name</th>
          <th>to CPR</th>
          <th>to CVR</th>
          <th>Start</th>
          <th>End</th>
          <th>Comment</th>
        </thead>
      ${this.relations.map(
				(r) => html`
        <tr>
            <td>${r.name1}</td>
            <td>${r.cpr1}</td>
            <td>${r.cvr1}</td>
            <td>${r.description}</td>
            <td>${r.code}</td>
            <td>${r.val}</td> 
            <td>${r.name2}</td>
            <td>${r.cpr2}</td>
            <td>${r.cvr2}</td>
            <td>${r.startdate}</td>
            <td>${r.enddate}</td>
            <td>${r.txt_reg_date}</td>
            <td>${r.adm_comment}</td>
        </tr>`,
			)}
    </table>
    </div>`;
	}

	renderPEPInfo() {
		return html`
      <div class="card">
        <div class="card-header">
          <h2>PEP Info</h2>
        </div>
        <table>
          <thead>
          <th>Imported</th>
            <th>Name</th>
            <th>UUID</th>
            <th>Birthdate</th>
            <th>Address</th>
            <th>Category</th>
            <th>Startdate</th>
            <th>Enddate</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Relevant</th>
            <th>Change</th>
          </thead>
        ${this.pepinfo.map(
					(r) => html`
          <tr>
              <td>${r.reg_date}</td>
              <td>${r.name}</td>
              <td>${r.uuid}</td>
              <td>${r.birthdate}</td>
              <td>${r.address}</td>
              <td>${r.category}</td> 
              <td>${r.startdate}</td>
              <td>${r.enddate}</td>
              <td>${r.c_reg_date}</td>
              <td>${r.c_mod_date}</td>
              <td>${r.relevant_yn}</td>
              <td><fm-button data-id="${r.pepdata_id}" @click="${this.changePEPData}">Change</fm-button></td>
          </tr>`,
				)}
      </table>
      </div>`;
	}

	renderRelation() {
		return html`
    <fm-relation account_id="${this.account_id}"></fm-relation>
    `;
	}

	renderPOAs() {
		let poasReceived = [];

		if (this.poa.received) {
			poasReceived = this.showAllPOAsReceived
				? this.poa.received
				: this.poa.received.slice(0, 5);
		}

		const renderShowMore =
			this.poa.received &&
			this.poa.received.length > 5 &&
			!this.showAllPOAsReceived;

		return html`
    <div class="card">
      <div class="card-header">
        <h2>Power of Attourneys (POAs)</h2>
      </div>
      <table>
        <thead>
          <th style="width: 100%;">
            POAs given (${this.poa.given?.length || 0})
          </th>
          <th style="text-align: right;">
            <router-link class="btn btn--small" to="/accounts/${this.account_id}/newpoa" class="btn btn--small">New</router-link>
          </th>
        </thead>
        ${
					this.poa.given === undefined || this.poa.given.length === 0
						? html`<tr><td colspan="2">None</td></tr>`
						: this.poa.given.map(
								(p) => html`
        <tr>
          <td>${p.name}</td>
          <td><button type="button" idx="${p.id}" @click="${this.removePOA}" class="btn btn--light">Remove</button></td>
        </tr>`,
							)
				}
        <thead>
          <th colspan="2">POAs received (${this.poa.received?.length || 0})</th>
        </thead>
        ${
					poasReceived === undefined || poasReceived.length === 0
						? html`<tr><td colspan="2">None</td></tr>`
						: poasReceived.map(
								(p) =>
									html`<tr><td>${p.name}</td><td><button type="button" idx="${p.id}" @click="${this.removePOA}" class="btn btn--light">Remove</button></td></tr>`,
							)
				}
        ${
					renderShowMore
						? html`<tr><td colspan="2"><button type="button" @click="${this.showAllPOAsReceivedClicked}" class="btn btn--small">Show all</button></td></tr>`
						: html``
				}
      </table>
    </div>`;
	}

	showAllPOAsReceivedClicked() {
		this.showAllPOAsReceived = true;
	}

	renderNewCustody() {
		return html`
   <div class="card">
   <fm-form name="custody" class="form-grid"  method="put" @submit="${
			this.saveNewCustody
		}">
   <input type="hidden" name="account_id" value="${this.account_id}">
   <button class="transparent" type="button" @click="${this.backtoList}">&larr;</button>
   <label class="form-field">Account
     ${this.custody.account_id} ${this.custody.account_name}
   </label>
   <label  class="form-field">Owner 1
      <fm-autocomplete 
        name="owner1_id"
        url="/lov/users?account_id=${this.account_id}"
        display="name"
        select="id"
        >
      </fm-autocomplete> 
   </label>
   <label  class="form-field">Owner 2
   <fm-autocomplete 
     name="owner2_id"
     url="/lov/users?account_id=${this.account_id}"
     display="name"
     select="id"
     >
   </fm-autocomplete> 
</label>

   <label  class="form-field">Type
   <fm-autocomplete 
   name="custodytype_id"
   url="/lov/custodytypes"
   display="description"
   select="id"
   >
 </fm-autocomplete> 
   </label>
   <label  class="form-field">Issuer (bank - pension)
   <fm-autocomplete 
   name="issuer_id"
   url="/lov/custodyissuer"
   display="name"
   select="id"
   >
  </fm-autocomplete> 
   </label>
   <label  class="form-field">External custody-number (pension)
   <input type="text" name="externalid">
   </label>
   <label  class="form-field">Nickname
     <input type="text" name="name">
   </label>
   <label  class="form-field">Remarks (not visible to user)
     <input type="text" name="remarks" >
   </label>
   <label  class="form-field">Special terms (visible to user)
     <input type="text" name="specialterms" >
   </label>
   <label  class="form-field">Pay-out bankaccount (if not NEM-KONTO)
     <input type="text" name="outbankaccount" >
   </label>
   <label  class="form-field">Contact name (bank)
   <input type="text" name="contact_name">
   </label>
   <label  class="form-field">Contact Email (bank)
   <input type="text" name="contact_email">
   </label>
   <label class="form-field"> Active
   <input type="checkbox" name="active_yn" ?checked=${true}>
   </label>
   <div class="form-space">
     <fm-button type="submit">Save</fm-button>
   </div>
   </fm-form>
 </div>
 `;
	}

	renderCustody() {
		const issuer = {
			id: this.custody.issuer_id,
			name: this.custody.issuer_name,
		};
		const owner1 = {
			id: this.custody.owner1_id,
			name: this.custody.owner1_name,
		};
		const owner2 = {
			id: this.custody.owner2_id,
			name: this.custody.owner2_name,
		};
		return html`
  <div class="card">
    <fm-form name="custody" class="form-grid"  method="put" @submit="${
			this.saveCustody
		}">
    <input type="hidden" name="id" value="${this.custody.id}">
    <button class="transparent" type="button" @click="${this.backtoList}">Close</button>
    <label class="form-field">Account
      ${this.custody.account_id} ${this.custody.account_name}
    </label>
    <label  class="form-field">Owner 1
    <fm-autocomplete 
        name="owner1_id" 
        url="/lov/users?account_id=${this.account_id}"
        value="${JSON.stringify(owner1)}" 
        display="name" 
        select="id">
    </fm-autocomplete>
    </label>
    <label  class="form-field">Owner 2
    <fm-autocomplete 
        name="owner2_id" 
        url="/lov/users?account_id=${this.account_id}"
        value="${JSON.stringify(owner2)}" 
        display="name" 
        select="id">
    </fm-autocomplete>
    </label>
    <label  class="form-field">Type
      ${this.custody.custody_type} 
    </label>
    <label  class="form-field">Nickname
      <input type="text" name="name" value="${this.custody.name || ""}"> 
    </label>
    <label  class="form-field">External custody (pension)
    <input type="text" name="externalid" value="${this.custody.externalid || ""}"> 
    </label>
    <label  class="form-field">Issuer (pension)
     <fm-autocomplete name="issuer_id" id="issuer_id" url="/lov/custodyissuer" value="${JSON.stringify(
				issuer,
			)}" display="name" select="id"></fm-autocomplete>
    </label>
    <label  class="form-field">Remarks (not visible to user)
      <input type="text" name="remarks" value="${this.custody.remarks || ""}">
    </label>
    <label  class="form-field">Special terms (visible to user)
      <input type="text" name="specialterms" value="${
				this.custody.specialterms || ""
			}">
    </label>
    <label  class="form-field">Pay-out bankaccount (if not NEM-KONTO)
      <input type="text" name="outbankaccount" value="${
				this.custody.outbankaccount || ""
			}">
    </label>
    <label  class="form-field">Contact name (bank)
    <input type="text" name="contact_name" value="${
			this.custody.contact_name || ""
		}">
    </label>
    <label  class="form-field">Contact Email (bank)
    <input type="text" name="contact_email" value="${
			this.custody.contact_email || ""
		}">
    </label>
    <label class="form-field"> Active
    <input type="checkbox" name="active_yn" ?checked="${
			this.custody.active_yn === "Y"
		}">
    </label>
    <div class="form-space">
      <fm-button type="submit">Save</fm-button>
    </div>
    </fm-form>
  </div>
  `;
	}

	renderCustodies() {
		if (this.custody_id != null) {
			if (this.custody_id === 0) {
				return this.renderNewCustody();
			}

			return this.renderCustody();
		}

		return html`
         <div class="card">
        <div class="card-header">
          <h2>Custodies</h2><fm-button type="button" class="btn" @click="${
						this.newCustody
					}">New</fm-button>
        </div>
      <table>
        <thead>
        <tr>
          <th>Custody Code</th>
          <th>Active</th>
          <th>Type</th>
          <th>Nick Name</th>
          <th>ShoppingList</th>
          <th>Accountnumber</th>
          <th>IBAN</th>
          <th>Owner(s)</th>
          <th>Remarks</th>
          <th>Special Terms</th>
          <th>Payout Accountnumber</th>
          <th>Apply BankAcc</th></tr>
      </thead>
      <tbody>
        ${this.custodies.map(
					(c) => html`
          <tr>
            <td>
              <button class="button-link" data-id="${c.id}" @click="${
								this.editCustody
							}"> ${c.custody_code}</button>
            </td>
            <td>${c.active_yn}</td>
            <td>${c.custody_type}</td>
            <td>${c.name}</td>
            <td>${c.basket_count}</td>
            <td>${c.accountnumber}</td>
            <td>${c.iban}</td>
            <td>${c.owner_name}</td>
            <td>${c.remarks}</td>
            <td>${c.specialterms}</td>
            <td>${c.outbankaccount}</td>
            <td><button class="button" @click="${this.accnumber}" ?disabled="${
							c.iban != null
						}" data-index="${c.id}">Apply account-number</button></td>
          </tr>
        `,
				)}
      </tbody>
      </table>
      </div>  `;
	}

	renderSignatures() {
		return html`
       <div class="card">
      <div class="card-header">
        <h2>Signed documents</h2>
      </div>
    <table>
      <thead>
      <tr>
        <th>Sign date</th>
        <th>Document</th>
        <th>User name</th>
        <th>PDF</th>
    </tr>
    </thead>
    <tbody>
      ${this.signatures.map(
				(c) => html`
        <tr>
          <td>${c.txt_reg_date}</td>
          <td>${c.document_code}</td>
          <td>${c.user_name}</td>
          <td class="numeric">
          <fm-button data-id="${c.id}" class="btn" @click="${this.nota}" style="display:inline;max-width:80px">PDF</fm-button>
        </td>
  
        </tr>
      `,
			)}
    </tbody>
    </table>
    </div>  `;
	}

	renderData() {
		return html`
    <div class="card">
    <div class="card-header">
      <h2>Account report</h2>
    </div>
    <fm-form id="return-parms" class="reportform no-print" >
      <div class="form-space">
        <label  class="form-field">From</label>
        <input type="date"  class="date-input" name="from"  value="${
					this.returnfrom
				}" >
      </div>
      <div class="form-space">
      <label class="form-field">To</label>
      <input type="date"  class="date-input" name="to" value="${this.returnto}" >
      </div>
      <div class="form-space">
      <label class="form-field">Owner</label>
       ${this.renderOwnerList()}
      </div>
      <div class="form-space">
        <fm-button id="submit_button" class="btn submit" @click="${
					this.returnrefresh
				}">Refresh</fm-button>
       <fm-button id="download_button" class="btn submit" @click="${
					this.returnPDF
				}">Download</fm-button>
      </div>
      <div class="form-space">
       <fm-button id="mail_button" class="btn submit" @click="${this.returnMail}">Internal Mail</fm-button>
      </div>
      <div class="form-space">
      <label class="form-field">Receiver</label>
       ${this.renderUserList()}
      </div>
  </fm-form>
  </div>
  
   ${renderAccountData(this.accountData)}
`;
	}

	newCustody() {
		this.custody_id = 0;
	}

	async editCustody(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.custody = await api.get(`/custodies/${id}`);
		this.custody_id = id; /* trigger re-render */
	}

	async backtoList() {
		this.custodies = await api.get(`/accounts/${this.account_id}/custodies`);
		this.custody_id = null; /* trigger re-render */
	}

	async saveCustody(event) {
		const target = event.target;
		const formvalue = target.value;

		const issuer = this.shadowRoot.querySelector("#issuer_id");
		if (issuer) {
			formvalue.issuer_id = issuer.value;
		}
		formvalue.active_yn = formvalue.active_yn ? "Y" : "N";
		try {
			const response = await api.put(`/custodies/${formvalue.id}`, formvalue);
			toast(response.message);
		} catch (err) {
			console.log(err);
			Sentry.captureException(err);
			const err_response = await err.json();
			toast(`Det lykkedes ikke at gemme (${JSON.stringify(err_response)})`);
		}
	}

	async saveNewCustody(event) {
		const target = event.target;
		const formvalue = target.value;
		const issuer = this.shadowRoot.querySelector("#issuer_id");
		if (issuer) {
			formvalue.issuer_id = issuer.value;
		}

		formvalue.active_yn = formvalue.active_yn ? "Y" : "N";

		try {
			const response = await api.post("/custodies", formvalue);

			toast(response.message);
			this.custodies = await api.get(`/accounts/${this.account_id}/custodies`);
			this.custody_id = null;
		} catch (err) {
			console.log(err);
			Sentry.captureException(err);
			const err_response = await err.json();
			toast(`Det lykkedes ikke at gemme (${JSON.stringify(err_response)})`);
		}
	}

	async accnumber(event) {
		const target = event.target;
		const custody_id = target.getAttribute("data-index");
		await api.get(`/custodies/${custody_id}/bankaccount`);
		this.custodies = await api.get(`/accounts/${this.account_id}/custodies`);
		await this.requestUpdate();
	}

	// Removes both POAs given and received
	async removePOA(event) {
		const id = event.target.getAttribute("idx");
		try {
			await api.delete(`/accounts/${this.account_id}/poa/${id}`);
			this.poa = await api.get(`/accounts/${this.account_id}/poa`);
			toast("POA removed");
		} catch (err) {
			Sentry.captureException(err);
			const msg = await err.json();
			toast(msg.message || "Fejl");
		}
	}

	async save() {
		try {
			const formdata = this.shadowRoot.querySelector("#form").value;
			const { pmaccounttype_id, name, cvr, email, phone } = formdata;
			this.row = await api.put(`/accounts/${this.account_id}`, {
				pmaccounttype_id,
				name,
				email,
				phone,
				cvr,
			});
			toast("Data saved");
		} catch (err) {
			Sentry.captureException(err);
			const msg = await err.json();
			toast(msg.message || "Error ");
		}
	}

	async returnrefresh(event) {
		const target = event.target;
		target.loading = true;
		const params = this.shadowRoot.querySelector("#return-parms").value;
		this.returnfrom = params.from;
		this.returnto = params.to;
		const owner = this.shadowRoot.querySelector("#ownerlist").value;
		try {
			this.accountData = await api.get(
				`/accounts/${this.account_id}/report?from=${this.returnfrom}&to=${this.returnto}&owner_id=${owner}`,
			);
		} catch (err) {
			Sentry.captureException(err);
			toast(`Error: ${JSON.stringify(err)}`);
		}
		target.loading = false;
	}

	async onNewRelation() {
		this.newRelation = true;
	}
	async nota(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const PDF = await API.get(`/nota/${id}/signed`);
		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF}`);
		a.setAttribute("download", "fm_signed_doc.pdf");
		a.click();
		target.loading = false;
	}

	async returnPDF() {
		const owner = this.shadowRoot.querySelector("#ownerlist").value;
		const response = await api.post(
			`/accounts/${this.account_id}/report?from=${this.returnfrom}&to=${this.returnto}&owner_id=${owner}`,
		);
		const a = document.createElement("a");
		a.href = `data:application/octet-stream;base64,${response.file}`;
		a.download = "Engagement.pdf";
		a.click();
	}

	async returnMail() {
		const owner = this.shadowRoot.querySelector("#ownerlist").value;
		const user_id = this.shadowRoot.querySelector("#userlist").value;
		const mail_button = this.shadowRoot.querySelector("#mail_button");
		mail_button.loading = true;
		try {
			await api.post(
				`/accounts/${this.account_id}/reportmail?from=${this.returnfrom}&to=${this.returnto}&owner_id=${owner}&user_id=${user_id}`,
			);
			mail_button.loading = false;
			toast("Internal mail sent");
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await err.json();
			mail_button.loading = false;
			toast(errmsg);
		}
	}

	async changePEPData(event) {
		try {
			const target = event.target;
			const id = target.getAttribute("data-id");
			this.pepinfo = await api.put(
				`/accounts/${this.account_id}/pepinfo/${id}`,
				{},
			);
		} catch (err) {
			Sentry.captureException(err);
			const e = await err.json();
			toast(JSON.stringify(e));
		}
	}

	openDeactivateAccountDialog() {
		this.openDeactivateDialog = true;
	}

	async deactivateAccount() {
		this.openDeactivateDialog = false;

		try {
			await api.put(
				`/accounts/${this.account_id}/deactivate?send_deactivate_mail=${this.sendDeactivateMail}`,
			);
			this.row = await api.get(`/accounts/${this.account_id}`);
			this.users = await api.get(`/accounts/${this.account_id}/users`);
			this.custodies = await api.get(`/accounts/${this.account_id}/custodies`);
			this.requestUpdate();

			toast("Account deactivated");
		} catch (err) {
			Sentry.captureException(err);
			if (err instanceof Response) {
				const body = await err.json();
				toast(`${body.status}: ${body.message}`);
			}
		}
	}
}
customElements.define("account-edit-view", AccountEditView);
