import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";

export default class ImageIDEditView extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
			},
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.users = [];
		this.updateFile = this.updateFile.bind(this);
		this.copyFile = this.copyFile.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.users = await api.get(`/accounts/${this.account_id}/users`);
		this.rows = await api.get(`/accounts/${this.account_id}/imageid`);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Image-ID</h2>
        </div>
        </div>
        <table>
        <thead>
        <tr>
          <th>User</th>
          <th>Image</th>
         <th>Expire</th>
        </tr>
        </thead>
        <tbody>
        ${(this.rows || []).map((r) => this.renderRow(r))}
        </tbody>
        </table>
    `;
	}

	renderRow(r) {
		return html`
  <tr>
    <td>
      ${this.renderUser(r)}
    </td>
    ${this.renderFile(r)}
  </tr>
   `;
	}

	renderUser(r) {
		return html`
  <select id="select_${r.file_id}">
    ${this.users.map((u) => this.renderUserOptions(r, u))}
  </select>
  <button class="button" @click="${this.updateUser}"  data-user="${
		r.user_id
	}" data-file="${r.file_id}">Save</button>
  `;
	}

	renderUserOptions(r, u) {
		if (r.user_id === u.id) {
			return html`<option value="${u.id}" selected>${u.name}</option>`;
		}

		return html`<option value="${u.id}">${u.name}</option>`;
	}

	renderFile(f) {
		if (f.file_id) {
			return html`
      <td>
      <object width="500" height="400" type="${f.mimetype}" data="${
				api.baseURL
			}/files/u/${f.file_id}?t=${api.token}"></object>
      </td>
      <td>
        <button class="button" @click="${this.copyFile}" data-index="${
					f.file_id
				}">Copy</button>
      </td>

      <td>
        expires: <input type="date" value="${f.expiredate || ""}" id="expire_${
					f.file_id
				}"> 
        <button class="button" @click="${this.updateFile}" data-index="${
					f.file_id
				}">Save</button>
      </td>
      `;
		}

		return "";
	}

	async updateFile(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const expire_input = this.shadowRoot.querySelector(`#expire_${file_id}`);
		if (expire_input) {
			try {
				const response = await api.put(`/users/files/${file_id}`, {
					expiredate: expire_input.value,
				});
				if (response.status === "OK") {
					toast("Expiredate saved");
				} else {
					toast(response.message);
				}
			} catch (err) {
				Sentry.captureException(err);
				toast("Error saving expiredate");
			}
		} else {
			toast("Internal Error: expiredate-input not found");
		}
	}

	async copyFile(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		try {
			const response = await api.post(`/users/files/${file_id}/copy`);
			if (response.status === "OK") {
				this.rows = await api.get(`/accounts/${this.account_id}/imageid`);
				toast("Copied");
			} else {
				toast(response.message);
			}
		} catch (err) {
			Sentry.captureException(err);
			toast("Copy Error");
		}
	}

	async updateUser(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-file");
		const user_id = target.getAttribute("data-user");
		const new_user = this.shadowRoot.querySelector(`#select_${file_id}`);
		if (new_user) {
			try {
				const response = await api.put(`/users/fileuser/${file_id}`, {
					user_id: new_user.value,
				});
				if (response.status === "OK") {
					toast("User-id changed");
					await this.fetch();
				} else {
					toast(response.message);
				}
			} catch (err) {
				Sentry.captureException(err);
				toast("Error saving user_id");
			}
		} else {
			toast(`Internal Error: select not found for user ${user_id}`);
		}
	}
}

customElements.define("imageid-edit-view", ImageIDEditView);
