import AccountView from "./account-view.js";
import AccountListView from "./account-list-view.js";
import AccountEditView from "./account-edit-view.js";
import AccountNewView from "./account-new-view.js";

import ImageIDView from "./imageid-list-view.js";
import ImageIDEditView from "./imageid-edit-view.js";

import AccountNewUserView from "./account-new-user-view.js";
import AccountReportView from "./account-report-view.js";
import AccountNewPOAView from "./account-newpoa-view.js";
import UsersListView from "./users-list-view.js";

import UserEditView from "./user-edit-view.js";
import UserFileUploadView from "./user-fileupload-view.js";
import UserNewEventView from "./user-newevent-view.js";
import UserNewQuestionView from "./user-newquestion-view.js";
import UsersQuestionView from "./user-question-view.js";
import UserTaxView from "./user-tax-view.js";
import UserRelationsView from "./user-relations-view.js";
import UserRealOwnerView from "./user-realowner-view.js";

import CustodiesListView from "./custodies-list-view.js";

import AccountReturnView from "./account-return-view.js";

import LoginsListView from "./logins-list-view.js";

import type { RouteOptions } from "runway";

export default {
	path: "accounts",
	component: AccountView,
	children: [
		{
			path: "",
			component: AccountListView,
		},
		{
			path: "users",
			component: UsersListView,
		},
		{
			path: "logins",
			component: LoginsListView,
		},
		{
			path: "relations",
			component: UserRelationsView,
		},
		{
			path: "realowners",
			component: UserRealOwnerView,
		},
		{
			path: "questions",
			component: UsersQuestionView,
		},
		{
			path: "custodies",
			component: CustodiesListView,
		},
		{
			path: "newquestion",
			component: UserNewQuestionView,
		},
		{
			path: "users/:user_id/newfile",
			component: UserFileUploadView,
			properties: ({ parameters }) => ({
				user_id: parameters.get("user_id"),
			}),
		},
		{
			path: "users/:user_id/newevent",
			component: UserNewEventView,
			properties: ({ parameters }) => ({
				user_id: parameters.get("user_id"),
			}),
		},
		{
			path: "users/:user_id/tax/:custody_id",
			component: UserTaxView,
			properties: ({ parameters }) => ({
				custody_id: parameters.get("custody_id"),
			}),
		},
		{
			path: "users/:user_id",
			component: UserEditView,
			properties: ({ parameters }) => ({
				userId: parameters.get("user_id"),
			}),
		},
		{
			path: "new",
			component: AccountNewView,
		},
		{
			path: "return",
			component: AccountReturnView,
		},
		{
			path: ":account_id/newpoa",
			component: AccountNewPOAView,
			properties: ({ parameters }) => ({
				access_id: parameters.get("account_id"),
			}),
		},
		{
			path: ":account_id/newuser",
			component: AccountNewUserView,
			properties: ({ parameters }) => ({
				account_id: parameters.get("account_id"),
			}),
		},
		{
			path: "imageid",
			component: ImageIDView,
		},
		{
			path: ":account_id/imageid",
			component: ImageIDEditView,
			properties: ({ parameters }) => ({
				account_id: parameters.get("account_id"),
			}),
		},
		{
			path: ":account_id/periodic-report",
			component: AccountReportView,
			properties: ({ parameters }) => ({
				account_id: parameters.get("account_id"),
			}),
		},
		{
			path: ":account_id",
			component: AccountEditView,
			properties: ({ parameters }) => ({
				account_id: parameters.get("account_id"),
			}),
		},
	],
} satisfies RouteOptions;
