import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class AMLPaymentSourceView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			approvalstatus: {
				type: Array,
			},
			custodysource_id: {
				type: String,
			},
			risklevels: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.approvalstatus = [];
		this.custodytypes = [];
		this.risklevels = [];
		this.dateStr = "";
		this.refresh = this.refresh.bind(this);
		this.onShow = this.onShow.bind(this);
		this.onAction = this.onAction.bind(this);
		this.onActionChange = this.onActionChange.bind(this);
		this.details = [];
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Payment sources</h2>
        </div>
        ${this.renderQueryForm()}
        <table>
          <thead>
            <tr>
             <th></th>
             <th>Custody_code</th>
             <th>Custody type</th>
             <th>Owners</th>
             <th>Advisor</th>
             <th>Account</th>
             <th>Reg.date</th>
             <th>Source#</th>
             <th>Source</th>             
             <th>#orders</th>
             <th class="numeric">Amount</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td><fm-button type="button" data-id="${row.id}" @click="${
									this.onShow
								}">Details</fm-button></td>
                <td>${row.custody_code}</td>
                <td>${row.custodytype_code}</td>
                <td>${row.owners}</td>
                <td>${row.advisor_name}</td>
                <td>${row.account_name}</td>
                <td>${row.cs_reg_date}</td>
                <td>${row.source_no}</td>
                <td>${row.source}</td>
                <td>${row.order_count}</td>
                <td class="numeric">${money(row.amountqc)}</td>
              </tr>
             ${this.renderDetails(row.id)}
             ${this.renderNewStatus(row)}

            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderNewStatus(row) {
		return html`
  <tr>
    <td colspan="2">
      <select id="select${row.id}" data-id="${row.id}" @change="${
				this.onActionChange
			}">
      ${this.approvalstatus.map(
				(r) =>
					html`<option ?selected=${r.id === row.approval_id} value="${r.id}">${
						r.description
					}</option>`,
			)}
    </select>
  </td>
  <td colspan="7">
    Comment: <input id="cmt${row.id}" style="width:100%" type="text" value="${
			row.adm_comment || ""
		}"/>
  </td>
  <td colspan="1">
   <fm-button type="button" data-id="${row.id}" @click="${this.onAction}">New status</fm-button>
  </td>
  <td colspan="2"></td>
</tr>
`;
	}

	renderDetails(id) {
		if (id === this.custodysource_id) {
			return html`
    <tr>
      <td colspan="5">
        <table>
          <thead>
            <tr>
              <th>Tradedate</th>
              <th class="numeric">Amount</th>
            </tr>
          </thead>
          <tbody>
            ${(this.details || []).map(
							(r) => html`
                <tr>
                  <td>${r.tradedate}</td>
                  <td class="numeric">${money(r.amountqc)}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </td>
    </tr>
    `;
		}

		return "";
	}

	renderCustodyTypes() {
		return html`
    <select name="custodytypes" multiple size="5" style="width:100%">
      ${this.custodytypes.map(
				(t) =>
					html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
						t.description
					}</option>`,
			)}
    </select>`;
	}

	renderStatus() {
		return html`
    <select name="approvalstatus" multiple size="5" style="width:100%">
       ${this.approvalstatus.map(
					(t) =>
						html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
							t.description
						}</option>`,
				)}
    </select>`;
	}

	renderQueryForm() {
		return html`
   <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
     <div class="form-field">
        <label>Created
          <fm-date-range id="created" name="created"  names="paymentsource-" ></fm-date-range>
         </label>
      </div>
      <div class="form-field">
          <label>#sources
          <input type="text"  name="sourcecount" style="width:50%"> (or more)
          </label>
       </div>
       <div class="form-field">
          <label>Custody types
            ${this.renderCustodyTypes()}
          </label>
       </div>
       <div class="form-field">
         <label>Approval status
            ${this.renderStatus()}
         </label>
        </div>
        <div class="form-field">
         <label>Search
          <input type="search"  name="query"  class="search-input" style="width:100%">
         </label>
       </div>
       <div class="form-field3">
       <label>Risk Levels
       ${this.renderRiskLevels()}
       </label>
     </div>

        <div class="form-field" style="display:flex">
          <fm-button type="submit" id="submit_button" style="max-width:80px;margin:4px;">Refresh</fm-button>
      </div>
  </fm-form>
    `;
	}

	renderRiskLevels() {
		return html`
    <select name="risklevels" multiple size="2" style="width:100%">
      ${this.risklevels.map(
				(t) =>
					html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
						t.description
					}</option>`,
			)}
    </select>`;
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("paymentsource-todate");
		const fromdate = localStorage.getItem("paymentsource-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("paymentsource-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("paymentsource-fromdate", todate);
		}

		this.types = localStorage.getItem("paymentsource-types") || "";
		this.custodytypes = await api.get(`/lov/custodytypes?s=${this.types}`);

		this.approval = localStorage.getItem("paymentsource-approval") || "";
		this.approvalstatus = await api.get(
			`/lov/approvalstatus?query=${this.approval}`,
		);
		const selectedrisk = localStorage.getItem("paymentsource-risk") || "";
		this.risklevels = await api.get(`/lov/userrisk?query=${selectedrisk}`);
	}

	async refresh() {
		this.details = [];
		this.custodysource_id = null; /* remove details */
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		localStorage.setItem("paymentsource-fromdate", dates.from);
		localStorage.setItem("paymentsource-todate", dates.to);
		const risk = `,${parms.risklevels.join(",")},`;
		localStorage.setItem("paymentsource-risk", risk);

		const reducer = (a, t) => `${a}${t},`;
		const types = parms.custodytypes.reduce(reducer, ",");
		localStorage.setItem("paymentsource-types", types);

		const approval = parms.approvalstatus.reduce(reducer, ",");
		localStorage.setItem("paymentsource-approval", approval);
		const count = parms.sourcecount ? parms.sourcecount : "0";
		try {
			const q = `/source?query=${parms.query}&from=${dates.from}&to=${dates.to}&types=${types}&approval=${approval}&count=${count}&risk=${risk}`;
			this.rows = await api.get(q);
		} catch (err) {
			Sentry.captureException(err);
			toast("Fetch failed");
			this.rows = [];
		}
		button.loading = false;
	}

	async onShow(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.details = await api.get(`/source/${id}`);
		this.custodysource_id = id;
	}

	async onAction(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const sel = this.shadowRoot.querySelector(`#select${id}`);
		const cmt = this.shadowRoot.querySelector(`#cmt${id}`);

		const approval_id = sel.value;
		const adm_comment = cmt.value;

		const obj = {
			id: id,
			adm_comment: adm_comment,
			approval_id: approval_id,
		};
		try {
			const response = await api.post(`/source/${id}`, obj);
			toast(response.message);
		} catch (err) {
			Sentry.captureException(err);
			toast(JSON.stringify(err));
		}
	}

	async onActionChange(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const sel = this.shadowRoot.querySelector(`#select${id}`);
		const cmt = this.shadowRoot.querySelector(`#cmt${id}`);

		const approval_id = sel.value;

		try {
			const response = await api.get(
				`/source/${id}/question?approval_id=${approval_id}`,
			);
			if (response.question !== "") {
				cmt.value = response.question;
			}
		} catch (err) {
			Sentry.captureException(err);
			toast(JSON.stringify(err));
		}
	}
}

customElements.define("aml-payment-source-view", AMLPaymentSourceView);
