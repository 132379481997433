import { html } from "lit";

import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

import api from "../../api/index.js";

export function orderRender(classobj) {
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Sign requests</h2>
        </div>
        <fm-form id = "params" class="form-grid" @submit="${classobj.refresh}">
          <div class="form-field">
             <label>Created
               <fm-date-range id="dates" name="dates"  names="signatures-" ></fm-date-range>
             </label>
           </div>
           <div class="form-field">
             <label>Query User
               <input type="search"  name="queryuser" value="${
									classobj.queryuser || ""
								}" class="search-input">
             </label>
             <label>Query Document
               <input type="search"  name="querydoc" value="${
									classobj.querydoc || ""
								}" class="search-input">
             </label>
            </div>
 
            <div class="form-field">
              <label>
              <input name="status" type="radio" value="open"> Open requests 
              </label>
              <label>
              <input name="status" type="radio" value="closed"> Closed requests 
              </label>
            </div>
          <fm-button type="submit" id="suibmit_button">Refresh</fm-button>
    </fm-form>
    </div>
    <div class="card">
        <table>
          <thead>
            <tr>
             <th></th>
             <th>ID</th>
             <th>Initiated</th>
             <th>Account</th>
             <th>User</th>
             <th>Document</th>
             <th>Created</th>
            </tr>
          </thead>
          <tbody>
            ${classobj.rows.map(
							(row) => html`
              <tr>
                <td>${renderButton(row, classobj)} </td>
                <td>${row.id}</td>
                <td>${row.host}</td>
                <td>${row.account_name}</td>
                <td>${row.user_name}</td>
                <td>${row.document_code}</td>
                <td>${row.txt_reg_date}</td>
              </tr>
              <tr>
                  <td colspan="7">${row.redirect_url}</td>
              </tr>
              ${renderResponses(row, classobj)}
            `,
						)}
          </tbody>
        </table>
      </div>`;
}

function renderButton(r, classobj) {
	if ((r.responses || []).length > 0) {
		return html`<fm-button type="button" style="max-width:80px;" data-id="${r.id}" @click="${classobj.onShow}">Show</fm-button>`;
	}

	return "";
}

function renderResponses(row, classobj) {
	if (row.id === classobj.signorder_id) {
		return html`${(row.responses || []).map(
			(r) => html`
        <tr>
          <td>${r.signatories}</td>
          <td colspan="4"> 
              <object width="600" height="600" type="application/pdf" data="${api.baseURL}/files/sr/${r.id}?t=${api.token}">
            </object>
          </td>
        </tr>`,
		)}`;
	}

	return "";
}
