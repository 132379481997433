import { LitElement, html } from "lit";
import api from "../api/index.js";
import tableStyles from "../styles/tables.js";
import sharedStyles from "../styles/shared.js";
import inputStyles from "../styles/input.js";

class AccountInfoElement extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
				reflect: true,
			},
			document_id: {
				type: String,
				reflect: true,
			},
			users: {
				type: Array,
			},
			relations: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.account_id = null;
		this.row = {};
		this.relations = [];
		this.users = [];
		this.signatures = [];
	}

	async connectedCallback() {
		super.connectedCallback();
		this.row = await api.get(`/accounts/${this.account_id}`);
		this.signatures = await api.get(
			`/documents/${this.document_id}/accounts/${this.account_id}`,
		);
		this.relations = await api.get(`/accounts/${this.account_id}/relations`);
		this.users = await api.get(`/accounts/${this.account_id}/users`);
	}

	render() {
		return html`
    <style>
    ${sharedStyles}
    ${tableStyles}
    ${inputStyles}
    </style>
     ${this.renderUsers()}
     ${this.renderRelations()}
    `;
	}

	renderUsers() {
		return html`
      <div clas="card">
      <h2>Users</h2>
        <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>CVR</th>
            <th>CPR</th>
            <th>Email</th>
            <th>Bind</th>
          </tr>
        </thead>
        <tbody>
            ${this.users.map((u) => this.renderUser(u))}
        </tbody>
        </table>
      </div>
      <div clas="card">
        <h2>Signatures</h2>
             <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Sign Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
          ${this.signatures.map((s) => this.renderSignature(s))}
          </tbody>
        </table>
      </div>
      `;
	}

	renderUser(u) {
		return html`
     <tr>
         <td>${u.name}</td>
         <td>${u.cvr}</td>
        <td>${u.cpr}</td>
        <td>${u.email}</td>
        <td>${u.bind}</td>
     </tr>`;
	}

	renderSignature(u) {
		return html`
  <tr>
      <td>${u.txt_reg_date}</td>
      <td>${u.user_name}</td>
     <td>${u.document_code}</td>
  </tr>`;
	}

	renderRelations() {
		return html`
    <div class="card">
    <h2>Relations</h2>
    <table>
    <thead>
    <tr>
      <th>Name</th>
      <th>CPR</th>
      <th>CVR</th>
      <th>Is</th>
      <th>To</th>
      <th>CPR</th>
      <th>CVR</th>
      <th>Start</th>
      <th>End</th>
    </tr>
    </thead>
    <tbody>
        ${this.relations.map((r) => this.renderRelation(r))}
    </tbody>
    </table>
    </div>`;
	}

	renderRelation(r) {
		return html`
    <tr>
    <td>${r.name1}</td>
    <td>${r.cpr1}</td>
    <td>${r.cvr1}</td>
    <td>${r.description}</td>
    <td>${r.name2}</td>
    <td>${r.cpr2}</td>
    <td>${r.cvr2}</td>
    <td>${r.startdate}</td>
    <td>${r.enddate}</td>

    </tr>
    
    `;
	}
}

customElements.define("fm-accountinfo", AccountInfoElement);
