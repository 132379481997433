import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import "../../components/fm-dialog.js";
import { toast } from "../../utils.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";
import router from "../../router/index.js";

import {
	type TaxFiling,
	individnrs,
	indberetningstyper,
	rettekoder,
	indberetningstypeDescription,
	rettekodeDescription,
	statusCodeDescription,
	taxFilingsStartYear,
} from "./tax-filing.js";

declare global {
	interface HTMLElementTagNameMap {
		"tax-filings-view": TaxFilingsView;
	}
}

@customElement("tax-filings-view")
export class TaxFilingsView extends LitElement {
	@state()
	private taxFilings: TaxFiling[] = [];

	@state()
	query = "";

	@state()
	showNewTaxFilingDialog = false;

	@state()
	newTaxFiling: {
		individnr: string | null;
		indkomstaar: number | null;
		indberetningstype: number | null;
		rettekode: number | null;
		name: string;
		description: string;
		file: string | null;
		fileName: string | null;
	} = {
		individnr: null,
		indkomstaar: null,
		indberetningstype: null,
		rettekode: 0,
		name: "",
		description: "",
		file: null,
		fileName: null,
	};

	newIndkomstaar: number[] = [];

	@state()
	newManualTaxFiling = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`
		div#search {
			display: flex;
			padding: 16px;
			gap: 16px;

			#search-button {
				display: flex;
				margin: auto 0px 4px 0px;
			}
		}

		table tr th.title {
			color: #333;
			background-color: #f0f0f0;
		}

		table tr td.nowrap {
			white-space: nowrap;
		}

		select[disabled] {
			background: #f0f0f0;
			color: #333;
		}

		.overlay #form {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.overlay #form label {
			margin-bottom: 4px;
		}

		.overlay #form div.row {
			display: flex;
			flex-direction: row;
			gap: 16px;
		}

		.overlay #form div.row > div {
			width: 100%;
		}

		.overlay div.buttons {
			justify-content: center;
		}`,
	];

	constructor() {
		super();
		this.resetNewTaxFiling();
	}

	async connectedCallback() {
		super.connectedCallback();
		this.taxFilings = await this.getTaxFilings();

		const startYear = taxFilingsStartYear;
		const thisYear = new Date().getFullYear();

		this.newIndkomstaar = Array.from(
			{ length: thisYear - startYear + 1 },
			(_, i) => thisYear - i,
		);
	}

	async getTaxFilings(query = "") {
		return await api.get(`/tax-filings?query=${query}`);
	}

	async search() {
		this.taxFilings = await this.getTaxFilings(this.query);
	}

	render() {
		return html`
			<div class="card">
				<div class="card-header">
					<h1>Indberetninger</h1>
					<fm-button type="button" class="btn btn--small" @click="${
						this.openNewTaxFilingDialog
					}">Ny indberetning</fm-button>
				</div>
				<div id="search">
					<label for="query" class="form-field">
						Søg (CPR-nummer eller kontonummer)
						<fm-text-input id="query" .value="${this.query}" @input=${(e) => {
							this.query = e.target.value;
						}}></fm-text-input>
					</label>
					<div id="search-button">
						<fm-button type="button" class="btn btn--small" @click="${
							this.search
						}">Søg</fm-button>
					</div>
				</div>
				<table>
				${this.renderDraftTaxFilings()}
				${this.renderSubmittedTaxFilings()}
				</table>
			</div>
			<fm-dialog
				.opened="${this.showNewTaxFilingDialog}"
				@close="${() => {
					this.showNewTaxFilingDialog = false;
				}}"
			>
			${
				this.showNewTaxFilingDialog
					? html`
				<div class="overlay">
					<h1>Ny indberetning</h1>
					<fm-form id="form">
						<div class="row">
							<label for="new-individnr" class="form-field">
								Individnr.
								<select id="new-individnr" @change=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										individnr: e.target.value,
										indberetningstype: null,
									};
								}}>
									<option></option>
								${individnrs.map((individnr) => {
									return html`<option value="${
										individnr.individnr
									}" ?selected=${
										individnr.individnr === this.newTaxFiling.individnr
									}>${individnr.description}</option>`;
								})}
								</select>
							</label>
						</div>
						<div class="row">
							<label for="new-indkomstaar" class="form-field">
								Skatteår
								<select id="new-indkomstaar" @change=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										indkomstaar: Number.parseInt(e.target.value),
									};
								}}>
									<option></option>
									${this.newIndkomstaar.map((indkomstaar) => {
										return html`<option ?selected=${
											indkomstaar === this.newTaxFiling.indkomstaar
										}>${indkomstaar}</option>`;
									})}
								</select>
							</label>
						</div>
						<div class="row">
							<label for="new-indberetningstype" class="form-field">
								Indberetningstype
								<select id="new-indberetningstype" ?disabled=${
									this.newTaxFiling.individnr !== "IFPA2235"
								} @change=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										indberetningstype: Number.parseInt(e.target.value),
									};
								}}>
									<option></option>
									${indberetningstyper.map((indberetningstype) => {
										return html`<option value="${
											indberetningstype.indberetningstype
										}" ?selected=${
											indberetningstype.indberetningstype ===
											this.newTaxFiling.indberetningstype
										}>${indberetningstype.description}</option>`;
									})}
								</select>
							</label>
						</div>
						<div class="row">
							<label for="new-rettekode" class="form-field">
								Rettekode
								<select id="new-rettekode" @change=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										rettekode: Number.parseInt(e.target.value),
									};
								}} disabled>
									<option></option>
									${rettekoder.map((rettekode) => {
										return html`<option value="${
											rettekode.rettekode
										}" ?selected=${
											rettekode.rettekode === this.newTaxFiling.rettekode
										}>${rettekode.description}</option>`;
									})}
								</select>
							</label>
						</div>
						<div class="row">
							<label for="new-name" class="form-field">
								Navn
								<fm-text-input id="new-name" .value="${
									this.newTaxFiling.name
								}" @input=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										name: e.target.value,
									};
								}}></fm-text-input>
							</label>
						</div>
						<div class="row">
							<label for="new-description" class="form-field">
								Beskrivelse
								<fm-text-input id="new-description" .value="${
									this.newTaxFiling.description
								}" @input=${(e) => {
									this.newTaxFiling = {
										...this.newTaxFiling,
										description: e.target.value,
									};
								}}></fm-text-input>
							</label>
						</div>
						<div class="row">
							<label for="new-filing-type" class="form-field">
								Indberetninger
								<select id="new-filing-type" @change=${(e) => {
									this.newManualTaxFiling = e.target.value === "manual";
								}}>
									<option value="automatic">Automatisk</option>
									<option value="manual">Manuel</option>
								</select>
							</label>
						</div>
						${
							!this.newManualTaxFiling
								? null
								: html`
						<div class="row">
							<label for="new-manual-filing" class="form-field">
								Manuel indberetningsfil
           						<input type="file" accept=".txt" id="new-manual-filing" @change="${this.onNewFileChange}" />
        					</label>
						</div>
						`
						}
						<div class="row buttons">
							<fm-button-v2
							class="button"
							type="button"
							@click="${() => {
								this.showNewTaxFilingDialog = false;
							}}"
							>
							Afbryd
							</fm-button-v2>
							<fm-button-v2
							id="create-button"
							class="button"
							type="button"
							@click="${this.submitNewTaxFiling}"
							.disabled=${!this.formValid()}
							>
							Opret
							</fm-button-v2>
						</div>
					</fm-form>
				</div>`
					: null
			}
			</fm-dialog>
		`;
	}

	renderDraftTaxFilings() {
		const filteredTaxFilings = this.taxFilings.filter((filing) => {
			return !filing.first_status_timestamp_ts;
		});

		return html`
			<tr>
				<th colspan="12" class="title">Kladder</th>
			</tr>
			${this.renderTaxFilings(filteredTaxFilings)}`;
	}

	renderSubmittedTaxFilings() {
		const filteredTaxFilings = this.taxFilings.filter((filing) => {
			return filing.first_status_timestamp_ts;
		});

		return html`
			<tr>
				<th colspan="12" class="title">Indberettet</th>
			</tr>
			${this.renderTaxFilings(filteredTaxFilings)}`;
	}

	renderTaxFilings(taxFilings: TaxFiling[]) {
		return html`
				<tr>
					<th>ID</th>
					<th></th>
					<th>Oprettet</th>
					<th>Individnr.</th>
					<th>Skatteår</th>
					<th>Indberetningstype</th>
					<th>Rettekode</th>
					<th>Individer</th>
					<th>Navn</th>
					<th>Status</th>
					<th>Statustidspunkt</th>
					<th>Handlinger</th>
				</tr>				
				${
					taxFilings.length === 0
						? html`<tr><td colspan="13">Ingen indberetninger</td></tr>`
						: taxFilings.map(
								(filing) => html`
				<tr>
					<td>${filing.id}</td>
					<td>${
						filing.manual_filename
							? `Manuel (${filing.manual_filename})`
							: "Automatisk"
					}</td>
					<td>${
						filing.reg_date
							? DateFormatter.format(
									new Date(filing.reg_date),
									"dd-mm-yyyy HH:MM:ss",
								)
							: "Ukendt"
					}</td>
					<td>${filing.individnr}</td>
					<td>${filing.indkomstaar}</td>
					<td>${
						filing.individnr === "IFPA2235"
							? indberetningstypeDescription(filing.indberetningstype)
							: ""
					}</td>
					<td>${rettekodeDescription(filing.rettekode)}</td>
					<td>${filing.entries}</td>
					<td title="${filing.description}">${filing.name}</td>
					<td class="nowrap">${statusCodeDescription(filing.latest_status_code)}</td>
					<td>${
						filing.latest_status_timestamp_ts
							? DateFormatter.format(
									new Date(filing.latest_status_timestamp_ts),
									"dd-mm-yyyy HH:MM:ss",
								)
							: ""
					}</td>
					<td><fm-button type="button" @click="${this.viewFiling}" data-id="${
						filing.id
					}" class="btn--small">Vis</fm-button></td>
				</tr>`,
							)
				}`;
	}

	viewFiling(e) {
		const id = e.target.getAttribute("data-id");
		router.push(`/tax/tax-filings/${id}`);
	}

	openNewTaxFilingDialog() {
		this.resetNewTaxFiling();
		this.showNewTaxFilingDialog = true;
	}

	resetNewTaxFiling() {
		this.newTaxFiling = {
			individnr: null,
			indkomstaar: null,
			indberetningstype: null,
			rettekode: 0, // Currently only supports rettekode 0, so default to this
			name: "",
			description: "",
			file: null,
			fileName: null,
		};
		this.newManualTaxFiling = false;
	}

	async onNewFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		this.newTaxFiling.file = await this.readFile(file);
		this.newTaxFiling.fileName = file.name;
	}

	formValid() {
		let isValid = true;

		isValid =
			this.newTaxFiling.individnr !== null &&
			this.newTaxFiling.indkomstaar !== null &&
			this.newTaxFiling.rettekode !== null &&
			this.newTaxFiling.name !== "";

		isValid =
			isValid &&
			(this.newTaxFiling.individnr !== "IFPA2235" ||
				this.newTaxFiling.indberetningstype !== null);

		return isValid;
	}

	async submitNewTaxFiling() {
		if (!this.formValid()) {
			toast("Felter mangler at blive udfyldt");
			return;
		}

		if (this.newManualTaxFiling && !this.newTaxFiling.file) {
			toast("Ingen indberetningsfil valgt");
			return;
		}

		const createButton: ButtonElementV2 | undefined | null =
			this.shadowRoot?.querySelector("#create-button");

		if (!createButton) {
			return;
		}

		createButton.loading = true;

		try {
			const newTaxFiling = await api.post("/tax-filings", {
				individnr: this.newTaxFiling.individnr,
				indkomstaar: this.newTaxFiling.indkomstaar,
				indberetningstype: this.newTaxFiling.indberetningstype,
				rettekode: this.newTaxFiling.rettekode,
				name: this.newTaxFiling.name,
				description: this.newTaxFiling.description,
				...(this.newTaxFiling.file
					? {
							file: this.newTaxFiling.file,
							fileName: this.newTaxFiling.fileName,
						}
					: {}),
			});

			if (newTaxFiling.status === "OK") {
				this.taxFilings = await this.getTaxFilings();
				this.showNewTaxFilingDialog = false;
			}

			toast("Saved");
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
		createButton.loading = false;
	}

	readFile(f: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			// Resolve the promise with the file content on load
			reader.onload = () => {
				resolve(reader.result as string);
			};

			// Reject the promise on error
			reader.onerror = () => {
				reject(new Error("Error reading file"));
			};

			// Start reading the file
			reader.readAsText(f);
		});
	}
}
