import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { sort } from "../../utils.js";

export default class UsersListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			usertypes: {
				type: Array,
			},
			risklevels: {
				type: Array,
			},
			approvaltypes: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.usertypes = [];
		this.risklevels = [];
		this.refresh = this.refresh.bind(this);
		this.applyCode = this.applyCode.bind(this);
		this.chkboxT = this.chkboxT.bind(this);
		this.sort = this.sort.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("users-todate", todate);
		const fromdate = localStorage.getItem("users-fromdate");
		if (!fromdate) {
			localStorage.setItem("users-fromdate", todate);
		}
		this.query = localStorage.getItem("users-query");
		this.pep = localStorage.getItem("users-pep") || "";
		this.nondomestic = localStorage.getItem("users-nondomestic") || "";
		this.infook = localStorage.getItem("users-infook") || "";
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("users-todate");
		const fromdate = localStorage.getItem("users-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("users-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("users-fromdate", todate);
		}
		this.query = localStorage.getItem("users-query");
		const selectedtypes = localStorage.getItem("users-types") || "";
		const selectedrisk = localStorage.getItem("users-risk") || "";
		this.usertypes = await api.get(`/lov/usertypes?query=${selectedtypes}`);
		this.risklevels = await api.get(`/lov/userrisk?query=${selectedrisk}`);
		this.approvaltypes = localStorage.getItem("users-approval-types") || "";
		this.pep = localStorage.getItem("users-pep") || "";
		this.nondomestic = localStorage.getItem("users-nondomestic") || "";
		this.infook = localStorage.getItem("users-infook") || "";
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }

	  .form-field3.column { display: flex; flex-direction: column; }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Users</h1>
       </div>

       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
         <div class="form-field">
           <label>Created
             <fm-date-range id="created" name="created"  names="users-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field">
           <label>Search
             <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
           </label>
         </div>
       <div class="form-field3">
       <label>User types
         ${this.renderUserTypes()}
       </label>
     </div>
     <div class="form-field3">
	 <label>Approval Types
       ${this.renderApprovalTypes()}
       </label>
       <label>Risk Levels
       ${this.renderRiskLevels()}
       </label>
     </div>
     <div class="form-field3 column">
       <label>
         <input type="checkbox" name="pep" ?checked="${this.pep}" />
		 Only PEP-related users
       </label>
       <label>
         <input type="checkbox" name="nondomestic" ?checked="${this.nondomestic}" />
		 Non-Domestic residents
       </label>
	   <label>
         <input type="checkbox" name="infook" ?checked="${this.infook}" />
		 Only info OK users
       </label>
     </div>
     <div  style="display:flex">
         <input type="text" id="user_code" style="max-width:80px">
         <fm-button @click="${this.applyCode}" style="max-width:80px">Apply</fm-button>
      </div>
       <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>
        <table>
          <thead>
            <tr>
              <th>Apply</th>
              <th>Responsible</th>
              <th data-key="username" @click="${this.sort}">Username</th>
              <th data-key="id" @click="${this.sort}">ID</th>
              <th data-key="account_id" @click="${this.sort}">AccountID</th>
              <th data-key="type" @click="${this.sort}">Type</th>
              <th data-key="account_name" @click="${this.sort}">Account name</th>
              <th data-key="name" @click="${this.sort}">Name</th>
              <th data-key="email" @click="${this.sort}">Email</th>
              <th data-key="cpr" @click="${this.sort}">Cpr</th>
              <th data-key="cvr" @click="${this.sort}">Cvr</th>
              <th data-key="tin" @click="${this.sort}">Tin</th>
              <th data-key="custodies" @click="${this.sort}">Custodies</th>
              <th data-key="uniquename" @click="${this.sort}">PID/RID</th>
              <th data-key="infook_yn" @click="${this.sort}">Info OK</th>
              <th data-key="approved_yn" @click="${this.sort}">Approved</th>
              <th data-key="highrisk_comment" @click="${this.sort}">High Risk</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td><input type="checkbox" class="apply" data-id="${row.id}" ></td>
                <td>${row.code}</td>
                <td><router-link to="/accounts/users/${row.id}"><a>${
									row.username || "(none)"
								}</a></router-link></td>
                <td>${row.id}</td>
                <td>${row.account_id}</td>
                <td>${row.type}/${row.type_code}</td>
                <td>${row.account_name}</td>
                <td>${row.name}</td>
                <td>${row.email}</td>
                <td>${row.cpr}</td>
                <td>${row.cvr}</td> 
                <td>${row.tin}</td> 
                <td>${row.custodies}</td> 
                <td>${row.uniquename}</td>
                <td>${row.infook_yn}</td>
                <td>${row.approved_yn}</td>
                <td>${row.highrisk_comment}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderUserTypes() {
		return html`
    <select name="usertypes" multiple size="5" style="width:100%">
      ${this.usertypes.map(
				(t) =>
					html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
						t.description
					}</option>`,
			)}
    </select>`;
	}

	renderApprovalTypes() {
		return html`
    <select name="approvaltypes" multiple size="2" style="width:100%">
		<option value="Y" ?selected=${
			this.approvaltypes ? this.approvaltypes.includes(",Y,") : false
		}>Approved</option>
		<option value="N" ?selected=${
			this.approvaltypes ? this.approvaltypes.includes(",N,") : false
		}>Not approved</option>
    </select>`;
	}

	renderRiskLevels() {
		return html`
    <select name="risklevels" multiple size="2" style="width:100%">
      ${this.risklevels.map(
				(t) =>
					html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
						t.description
					}</option>`,
			)}
    </select>`;
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		const types = `,${parms.usertypes.join(",")},`;
		const approvals = `,${parms.approvaltypes.join(",")},`;
		const risk = `,${parms.risklevels.join(",")},`;

		const pep = parms.pep || "";
		const nondomestic = parms.nondomestic || "";
		const infook = parms.infook ? "Y" : "";

		localStorage.setItem("users-types", types);
		localStorage.setItem("users-approval-types", approvals);
		localStorage.setItem("users-risk", risk);
		localStorage.setItem("users-fromdate", dates.from);
		localStorage.setItem("users-todate", dates.to);
		localStorage.setItem("users-query", parms.query);
		localStorage.setItem("users-pep", pep);
		localStorage.setItem("users-nondomestic", nondomestic);
		localStorage.setItem("users-infook", infook);
		this.query = parms.query;
		const q = `/users?fromdate=${dates.from}&todate=${dates.to}&query=${this.query}&types=${types}&risk=${risk}&pep=${pep}&nondomestic=${nondomestic}&infook=${infook}&approvals=${approvals}`;
		this.rows = await api.get(q);
		submit_button.loading = false;
	}

	async applyCode() {
		const code = this.shadowRoot.querySelector("#user_code").value;
		const chks = this.shadowRoot.querySelectorAll("input:checked");
		const ids = [];
		for (const chk of chks) {
			ids.push(chk.getAttribute("data-id"));
		}
		const data = { code: code, users: ids };
		await api.post("/users/code", data);
		await this.refresh();
		this.requestUpdate();
	}

	chkboxT(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		for (let i = 0; i < this.usertypes.length; i++) {
			if (this.usertypes[i].id === id) {
				if (target.checked) {
					this.usertypes[i].selected = "Y";
				} else {
					this.usertypes[i].selected = "N";
				}
			}
		}
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}
}

customElements.define("users-list-view", UsersListView);
