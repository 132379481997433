import AMLInvestorCountryListView from "./aml-investor-country-list-view.js";
import AMLCountryView from "./aml-country-view.js";
import AMLSectorView from "./aml-sector-view.js";
import AMLInvestorSectorListView from "./aml-investor-sector-list-view.js";
import AMLRealownerView from "./aml-realowner-view.js";
import AMLUnAnsweredView from "./aml-unanswered-view.js";
import AMLPaymentSourceView from "./aml-payment-source-view.js";
import AMLDeactivateView from "./aml-deactivate-view.js";
import AMLOrderflowView from "./aml-orderflow-view.js";
import AMLRiskInflowView from "./aml-riskinflow-view.js";
import AMLRiskOutflowView from "./aml-riskoutflow-view.js";
import AMLAnswersView from "./aml-answers-view.js";
import AMLJobsView from "./aml-jobs-view.js";

import AMLView from "./aml-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "aml",
	component: AMLView,
	children: [
		{
			path: "",
			exact: true,
			redirect: "jobs",
		},
		{
			path: "countries",
			component: AMLCountryView,
		},
		{
			path: "sectors",
			component: AMLSectorView,
		},
		{
			path: "investorcountries",
			component: AMLInvestorCountryListView,
		},
		{
			path: "investorsectors",
			component: AMLInvestorSectorListView,
		},
		{
			path: "realowners",
			component: AMLRealownerView,
		},
		{
			path: "uquestions",
			component: AMLUnAnsweredView,
		},
		{
			path: "sources",
			component: AMLPaymentSourceView,
		},
		{
			path: "deactivate",
			component: AMLDeactivateView,
		},
		{
			path: "orderflow",
			component: AMLOrderflowView,
		},
		{
			path: "riskinflow",
			component: AMLRiskInflowView,
		},
		{
			path: "riskoutflow",
			component: AMLRiskOutflowView,
		},
		{
			path: "answers",
			component: AMLAnswersView,
		},
		{
			path: "jobs",
			component: AMLJobsView,
		},
	],
} satisfies RouteOptions;
