import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { addDays } from "../../utils.js";

export default class UsersQuestionView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>AML Questions</h1>
          <router-link class="btn btn--light" to="/accounts/newquestion">
          <a href="/accounts/newquestion">New</a>
          </router-link>
        </div>
        <div class="card-block">
        Asked From
          <input type="date" id="fromdate"  class="date-input" value="${
						this.fromdate
					}">
          <input type="search" class="search-input" id="search" placeholder="Search" @change="${
						this.onInput
					}">
          <fm-button class="btn" @click="${this.refresh}" >Refresh</fm-button>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="ask_date" on-click="${this.sort}">Asked</th>
              <th data-key="user_name" on-click="${this.sort}">Client</th>
              <th data-key="question" on-click="${this.sort}">Question</th>
              <th data-key="asked_by" on-click="${this.sort}">Asked/Answered By</th>
              </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(q) => html`
              <tr>
                <td>${q.ask_date}</td>
                <td>${q.user_name}</td>
                <td>${q.question} </td>
                <td>${q.asked_by}</td>              
              </tr>
              ${this.renderAnswers(q.answers)}
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderAnswers(answers) {
		if (answers) {
			return html`${answers.map((a) => this.renderAnswer(a))}`;
		}
	}

	renderAnswer(a) {
		return html`
     <tr>
       <td>${a.answer_date}</td>
       <td></td>
       <td>${a.answer} ${a.answer_other}</td>
       <td>${a.answered_by}</td>
     </tr>
     `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.onInput = this.onInput.bind(this);
		this.refresh = this.refresh.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -100));
	}

	onInput(event) {
		const input = event.target;
		this.query = input.value;
		input.addEventListener("input", (_e) => {
			clearTimeout(defer);
			defer = setTimeout(() => {
				this.fetch(input.value);
			}, 200);
		});
	}

	async fetch() {
		const fromdate = this.shadowRoot.querySelector("#fromdate").value;
		this.rows = await api.get(
			`/users/questions?qfrom=${fromdate}&query=${this.query}`,
		);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		await this.fetch();
		target.loading = false;
	}
}

customElements.define("users-question-view", UsersQuestionView);
