import ReportView from "./report-view.js";
import ReportCompanyView from "./report-company-view.js";
import ReportParticipationView from "./report-participation-view.js";
import ReportAccountReturnView from "./report-accountreturn-view.js";
import ReportSQLView from "./report-sql-view.js";
import ReportCashflowView from "./report-cashflow-view.js";
import ReportFundStatusView from "./report-fundstatus-view.js";
import ReportStatementView from "./report-statement-view.js";
import ReportPEPDataView from "./report-pepdata-view.js";
import ReportMessagesView from "./report-messages-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "reports",
	component: ReportView,
	children: [
		{
			path: "",
			exact: true,
			redirect: "companies",
		},
		{
			path: "companies",
			component: ReportCompanyView,
		},
		{
			path: "participation",
			component: ReportParticipationView,
		},

		{
			path: "accountreturn",
			component: ReportAccountReturnView,
		},
		{
			path: "cashflow",
			component: ReportCashflowView,
		},
		{
			path: "sql",
			component: ReportSQLView,
		},
		{
			path: "fundstatus",
			component: ReportFundStatusView,
		},
		{
			path: "statement",
			component: ReportStatementView,
		},
		{
			path: "pepdata",
			component: ReportPEPDataView,
		},
		{
			path: "messages",
			component: ReportMessagesView,
		},
	],
} satisfies RouteOptions;
