import { LitElement, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import api from "../../api/index.js";
import { toast } from "../../utils.js";
import { styles as minimal } from "../../styles/minimal.js";
import "../../components/fm-button-v2.js";
import "../../components/fm-card.js";
import "../../components/fm-form-field.js";
import "../../components/fm-text-input.js";
import { get, post, put } from "../../api/client.js";

type Thread = {
	thread_id: number;
	thread_status: string;
	direction: Direction;
	latest_direction: Direction;
	latest_event: string;
	reg_date: string;
	reply_yn: string;
	thread_action: string;
	sender_user_id: number;
	sender_user_name: string;
	sender_account_name: string;
	receiver_name: string;
	subject: string;
};

type Direction = "IN" | "OUT";

type Message = TextMessage | FileMessage;

type TextMessage = {
	type_code: "MSG";
	reg_date: string;
	a_message: string | null;
	b_message: string | null;
};

type FileMessage = {
	type_code: "ATTACH";
	reg_date: string;
	name: string;
	a_message: string | null;
	b_message: string | null;
};

type CurrentFile = {
	name: string;
	type: string;
	content: string;
	size: number;
};

const formatFileSize = (n: number): string => {
	if (n > 1_000_000) {
		return `${(n / 1_000_000).toFixed(2)} MB`;
	}

	if (n > 1_000) {
		return `${(n / 1_000).toFixed(2)} KB`;
	}

	return `${n} B`;
};

@customElement("thread-view")
export class ThreadView extends LitElement {
	static styles = [
		minimal,
		css`
			:host {
				display: block;
				margin: 24px auto;
				max-width: 1000px;
			}

			.center-padded {
				width: 100%;
				padding: 32px 0;
				display: flex;
				justify-content: center;
			}
			
			.full-width {
				width: 100%;
			}

			.thread-details {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 16px;
				gap: 16px;
				border-bottom: 1px solid #ddd;
			}

			.thread-content {
				display: flex;
				flex-direction: column;
				padding: 16px;
				gap: 16px;
			}

			.thread-message {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				column-gap: 16px;
			}

			.thread-message-date {
				font-size: 12px;
			}

			.thread-message-content {
				border: 1px solid #ddd;
				border-radius: 2px;
				padding: 8px;
				margin: 4px 0;
				width: 100%;
			}

			.thread-message-content.file {
				cursor: pointer;
				border-style: dashed;
				border-color: #aaa;
				background: none;
				transition: background-color 0.15s ease;
			}

			.thread-message-content.file:hover {
				background-color: #eee;
			}

			.thread-compose {
				border-top: 1px solid #ddd;
				display: flex;
				flex-wrap: wrap;
			}

			.thread-compose-input {
				padding: 8px;
				border: 1px solid #ddd;
				width: 100%;
				height: 120px;
				resize: vertical;
				background: #eee;
				font-family: var(--primary-font);
			}

			.thread-compose-bar {
				display: flex;
				width: 100%;
				padding: 16px;
				gap: 16px;
			}

			.thread-compose-actions {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 8px;
			}

			.thread-compose-add-file {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px;
				border-radius: 2px;
				transition: background-color 0.15s ease;
			}

			.thread-compose-add-file:hover {
				background-color: #eee;
			}

			input[type="file"] {
				display: none;
			}

			.thread-attached-files {
				display: flex;
				flex-direction: column;
				padding: 16px;
				gap: 8px;
			}

			.thread-attached-file-size {
				font-size: 12px;
				color: #999;
				padding: 8px;
			}
		`,
	];

	@property()
	thread_id: string | null = null;

	@state()
	private thread: Thread | null = null;

	@state()
	private messages: Message[] | null = null;

	@state()
	private files: CurrentFile[] = [];

	constructor() {
		super();
		this.onSubmitMessage = this.onSubmitMessage.bind(this);
		this.onClickClose = this.onClickClose.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		const threadId = this.thread_id;
		if (threadId === null) {
			console.error("Missing `thread_id`");
			return;
		}

		this.fetchThread(threadId);
	}

	render() {
		return html`
			<fm-card>
				${
					this.thread !== null && this.messages !== null
						? this.renderThread(this.thread, this.messages)
						: this.renderLoading()
				}
			</fm-card>
		`;
	}

	private renderLoading() {
		return html`
			<div class="center-padded">
				<fm-progress-dots></fm-progress-dots>
			</div>
		`;
	}

	private renderThread(thread: Thread, messages: Message[]) {
		return html`
			<h1 slot="card-header">
				Thread
			</h1>
			<fm-button-v2
				slot="card-header"
				light
				?disabled="${thread.thread_status === "CLOSED"}"
				@click="${this.onClickClose}"
			>
				Close
			</fm-button-v2>
			<div class="thread-details">
				<fm-form-field label="Subject" class="full-width">
					<fm-text-input
						disabled
						defaultValue="${thread.subject}"
					></fm-text-input>
				</fm-form-field>
				<fm-form-field label="Sender">
					<fm-text-input
						disabled
						defaultValue="${thread.sender_user_name}"
					></fm-text-input>
				</fm-form-field>
				<fm-form-field label="Recipient">
					<fm-text-input
						disabled
						defaultValue="${thread.receiver_name}"
					></fm-text-input>
				</fm-form-field>
				<fm-form-field label="Created at">
					<fm-text-input
						disabled
						defaultValue="${thread.reg_date}"
					></fm-text-input>
				</fm-form-field>
				<fm-form-field label="Status">
					<fm-text-input
						disabled
						defaultValue="${thread.thread_status}"
					></fm-text-input>
				</fm-form-field>
			</div>
			<div class="thread-content">
				${messages.map((row) =>
					this.renderMessage(
						thread.sender_user_name,
						thread.receiver_name,
						row,
					),
				)}
			</div>
			<div class="thread-compose">
				<div class="thread-compose-bar">
					<textarea class="thread-compose-input"></textarea>
					<div class="thread-compose-actions">
						<fm-button-v2 @click="${this.onSubmitMessage}">Send</fm-button-v2>
						<label class="thread-compose-add-file">
							+ Add file
							<input type="file" @change="${this.onFileChange}" />
						</label>
					</div>
				</div>
				${this.files.length > 0 ? this.renderAttachedFiles(this.files) : null}
			</div>
		`;
	}

	private renderAttachedFiles(files: CurrentFile[]) {
		return html`
			<div class="thread-attached-files">
				${files.map(
					(file) => html`
					<div class="thread-attached-file">
						- ${file.name}
						<span class="thread-attached-file-size">(${formatFileSize(
							file.size,
						)})</span>
					</div>
				`,
				)}
			</div>
		`;
	}

	private renderMessage(
		threadSender: string,
		threadReceiver: string,
		m: Message,
	) {
		const senderName = m.a_message !== null ? threadSender : threadReceiver;
		const sentAt = m.reg_date;
		const content = m.a_message ?? m.b_message ?? "";

		if (m.type_code === "MSG") {
			return this.renderTextMessage(senderName, sentAt, content);
		}

		if (m.type_code === "ATTACH") {
			return this.renderFileMessage(senderName, sentAt, m.name, content);
		}
	}

	private renderTextMessage(
		senderName: string,
		sentAt: string,
		content: string,
	) {
		return html`
			<div class="thread-message">
				<span class="thread-message-username">
					${senderName}
				</span>
				<span class="thread-message-date">
					${sentAt}
				</span>
				<div class="thread-message-content">
					${content}
				</div>
			</div>
		`;
	}

	private renderFileMessage(
		senderName: string,
		sentAt: string,
		name: string,
		file: string,
	) {
		const href = file.includes("data:")
			? file
			: `data:application/octet-stream;base64,${file}`;

		return html`
			<div class="thread-message">
				<span class="thread-message-username">
					${senderName}
				</span>
				<span class="thread-message-date">
					${sentAt}
				</span>
				<a
					class="thread-message-content file"
					download="${name}"
					href="${href}"
				>
					${name}
				</a>
			</div>
		`;
	}

	private onClickClose(_event: MouseEvent) {
		const threadId = this.thread_id;
		if (!threadId) {
			console.error("`thread_id` is null");
			return;
		}

		this.closeThread(threadId);
	}

	private onSubmitMessage(_event: MouseEvent) {
		const newMessageElement: HTMLTextAreaElement | null =
			this.shadowRoot?.querySelector(".thread-compose-input") ?? null;
		if (newMessageElement === null) {
			console.error("Failed to find `.thread-compose-input`");
			return;
		}

		const threadId = this.thread_id;
		if (!threadId) {
			console.error("`thread_id` is null");
			return;
		}

		const message = newMessageElement.value;
		this.sendMessage(threadId, message, this.files);
	}

	private onFileChange(event: InputEvent) {
		event.preventDefault();
		const target = event.target;
		if (target === null) {
			console.error("Expected `event.target` to not be null");
			return;
		}

		if (!(target instanceof HTMLInputElement)) {
			console.error(`Expected ${target} to be an HTMLInputElement`);
			return;
		}

		const files = target.files ?? [];

		if (files.length !== 1) {
			console.error("Expected exactly 1 file");
			return;
		}

		const file = files[0];
		this.readFile(file, { name: file.name, type: file.type });
	}

	private async fetchThread(threadId: string) {
		const [thread, messages] = await Promise.all([
			get<{ data: Thread }>(`/mails/${threadId}`),
			get<{ data: Message[] }>(`/mails/${threadId}/messages`),
		]);

		if (thread.ok) {
			this.thread = thread.value.data;
		} else {
			toast("Failed to load thread");
		}
		if (messages.ok) {
			this.messages = messages.value.data.reverse();
		} else {
			toast("Failed to load messages");
		}
	}

	private async closeThread(threadId: string) {
		const response = await put(`/mails/${threadId}/close`, {});
		if (response.ok === false) {
			toast("Failed to close thread");
			console.error(response.error);
		}
	}

	private async sendMessage(
		threadId: string,
		message: string,
		files: CurrentFile[],
	) {
		await api.post(`/mails/${threadId}/messages`, {
			message,
		});

		const newMessageElement: HTMLTextAreaElement | null =
			this.shadowRoot?.querySelector(".thread-compose-input") ?? null;

		if (newMessageElement !== null) {
			newMessageElement.value = "";
		}

		await Promise.all(
			files.map((file) =>
				post(`/mails/${threadId}/attachment`, {
					name: file.name,
					mimetype: file.type,
					content: file.content,
				}),
			),
		);
		this.files = [];

		toast("Message sent");
		await this.fetchThread(threadId);
	}

	private readFile(file: File, meta: { name: string; type: string }) {
		const reader = new FileReader();
		reader.addEventListener("loadend", (e: ProgressEvent<FileReader>) => {
			const content = e.target?.result;
			if (typeof content === "string" && content.includes("data:")) {
				this.files = [
					...this.files,
					{
						name: meta.name,
						type: meta.type,
						content: content.substring(1 + content.indexOf(",")),
						size: file.size,
					},
				];
				return;
			}

			toast("Failed to read file");
		});
		reader.readAsDataURL(file);
	}
}
