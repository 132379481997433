import { html } from "lit";
import { money } from "../../formatting/numberformats.js";

function renderAccountCustodies(c) {
	return html`
    <div class="card-header">
      <h2>Investeringskonti i engagementet</h2>
   </div>
      <div class="card-block">
        <table style="border:1px solid black;padding:4px">
        <tr>
        <th style="text-align:left">Custody Code</th>
        <th style="text-align:left">Name</th>
        <th style="text-align:left">Type</th>
        <th style="text-align:left">Owner</th>
        <th style="text-align:left">Særlige betingelser</th>
        <th class="numeric">Saldo</th>
        </tr>
        ${c.map(
					(r) => html`
            <tr>
              <td>${r.custody_code}</td>
              <td>${r.name}</td>
              <td>${r.custodytype}</td>
              <td>${r.custody_owner}</td>
              <td>${r.specialterms}</td>
              <td class="numeric">${money(r.ult_marketvalue_pc)}</td>
            </tr>
          `,
				)}
      </table>
   </div>
  `;
}

function renderAccountUsers(c) {
	return html`
  <div class="card-header">
    <h2>Personer i engagementet</h2>
</div>
    <div class="card-block">
  <table style="border:1px solid black;padding:4px">
  <tr>
  <th style="text-align:left">Name</th>
  <th style="text-align:left">CPR/CVRe</th>
  <th style="text-align:left">Type</th>
  </tr>
  ${c.map(
		(r) => html`
      <tr>
         <td>${r.name}</td>
        <td>${r.cpr || ""}${r.cvr || ""}}</td>
        <td>${r.type_code}</td>
      </tr>
    `,
	)}
    </table>
</div>
  `;
}

function renderAccountPOA(p) {
	return html`
  <div class="card-header">
    <h2>Ekstern adgang</h2>
  </div>
  <div class="card-block">
    <table style="border:1px solid black;padding:4px">
    <tr>
    <th style="text-align:left">Name</th>
    <th style="text-align:left">CPR/CVR</th>
    <th style="text-align:left">Type</th>
    </tr>
    ${p.map(
			(r) => html`
        <tr>
          <td>${r.name}</td>
          <td>${r.cpr || ""}${r.cvr || ""}}</td>
          <td>${r.type_code}</td>
        </tr>
      `,
		)}
      </table>
 </div>
  `;
}

export function renderAccountData(d) {
	if (d.custodies) {
		return html`
        <div class="card">
         ${renderAccountCustodies(d.custodies || [])}
         ${renderAccountUsers(d.users || [])}
         ${renderAccountPOA(d.poa || [])}
         </div>`;
	}

	return "";
}
