import AdvisorView from "./advisor-view.js";
import AdvisorListView from "./advisor-list-view.js";
import AdvisorFeeView from "./advisor-fee-view.js";
import AdvisorOrdersView from "./advisor-orders-view.js";
import AdvisorNewOrdersView from "./advisor-neworders-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "advisors",
	component: AdvisorView,
	children: [
		{
			path: "",
			component: AdvisorListView,
		},
		{
			path: "fee",
			component: AdvisorFeeView,
		},
		{
			path: "orders",
			component: AdvisorOrdersView,
		},
		{
			path: "neworders",
			component: AdvisorNewOrdersView,
		},
	],
} satisfies RouteOptions;
