import { LitElement, css, html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { customElement, state } from "lit/decorators.js";

import { styles as minimalStyles } from "../../styles/minimal.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { date as formatDate } from "../../formatting/dateformats.js";
import { addDays, toast } from "../../utils.js";
import { get } from "../../api/client.js";
import type { DateInputElement } from "../../components/fm-date-input.js";
import type { TextInputElement } from "../../components/fm-text-input.js";
import "../../components/fm-text-input.js";
import "../../components/fm-card.js";

type Thread = {
	thread_id: number;
	thread_status: string;
	thread_action: string;
	sender_account_name: string;
	sender_user_name: string;
	sender_user_id: number;
	receiver_name: unknown;
	direction: Direction;
	latest_direction: Direction;
	txt_latest_event: string;
	latest_event: string;
	reply_yn: string;
	subject: string;
};

type Direction = "IN" | "OUT";

const formatDirection = (direction: Direction): string => {
	if (direction === "IN") {
		return "Received";
	}

	return "Sent";
};

@customElement("threads-list-view")
export class ThreadsListView extends LitElement {
	static styles = [
		minimalStyles,
		tableStyles,
		css`
			:host {
				display: block;
				margin: 24px auto;
				max-width: 1500px;
			}

			.filters {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 16px;
				min-height: 56px;
				border-bottom: 1px solid #ddd;
				gap: 16px;
			}

			fm-form-field {
				flex: 1;
			}
		`,
	];

	@state()
	private threads: Thread[] = [];

	private defaultValues = {
		fromDate:
			localStorage.getItem("mails-fromdate") ??
			formatDate(addDays(new Date(), -5)),
		toDate: localStorage.getItem("mails-todate") ?? formatDate(new Date()),
		query: localStorage.getItem("mails-query") ?? "",
		open: localStorage.getItem("mails-open") !== "false",
		broadcast: localStorage.getItem("mails-broadcast") === "true",
	};

	constructor() {
		super();
		this.fetchThreads = this.fetchThreads.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		this.fetchThreads(
			this.defaultValues.fromDate,
			this.defaultValues.toDate,
			this.defaultValues.query,
			this.defaultValues.open,
			this.defaultValues.broadcast,
		);
	}

	render() {
		return html`
			<fm-card>
				<h1 slot="card-header">Messages</h1>
				<div class="filters">
					<fm-form-field label="From">
						<fm-date-input
							id="from"
							name="from-date"
							.defaultValue="${this.defaultValues.fromDate}"></fm-date-input>
					</fm-form-field>
					<fm-form-field label="To">
						<fm-date-input
							id="to"
							name="to-date"
							.defaultValue="${this.defaultValues.toDate}"></fm-date-input>
					</fm-form-field>
					<fm-form-field label="Search">
						<fm-text-input
							id="search"
							name="search"
							@submit="${this.onSubmitSearch}"
						></fm-text-input>
					</fm-form-field>
					<fm-form-field label="Filter">
						<label>
							<input
								type="checkbox"
								id="open"
								name="open"
								?checked="${this.defaultValues.open}" />
							Open threads
						</label>
						<label>
							<input
								type="checkbox"
								id="broadcast"
								name="broadcast"
								?checked="${this.defaultValues.broadcast}" />
							Broadcast messages
						</label>
					</fm-form-field>
					<fm-button-v2 id="submit_button" @click="${this.onClickRefresh}">Refresh</fm-button-v2>
				</div>
				<table>
					<thead>
						<tr>
							<th>Subject</th>
							<th>Sender</th>
							<th>Recipient</th>
							<th>Last Updated</th>
						</tr>
					  </thead>
					  <tbody>
						${repeat(
							this.threads,
							(thread) => thread.thread_id,
							(thread) => html`
						<tr>
							<td>
								<router-link>
									<a href="/messages/${thread.thread_id}">
										${thread.subject}
									</a>
								</router-link>
							</td>
							<td>${thread.sender_user_name}</td>
							<td>${thread.receiver_name}</td>
							<td>${thread.txt_latest_event} (${formatDirection(
								thread.latest_direction,
							)})</td>
						</tr>
					`,
						)}
					  </tbody>
				</table>
			</fm-card>
    	`;
	}

	onClickRefresh(_event: MouseEvent) {
		const inputs = this.queryInputs();
		if (inputs) {
			this.saveInputs(inputs);
			this.fetchThreads(
				inputs.fromDate,
				inputs.toDate,
				inputs.search,
				inputs.open,
				inputs.broadcast,
			);
		}
	}

	onSubmitSearch(_event: Event) {
		const inputs = this.queryInputs();
		if (inputs) {
			this.saveInputs(inputs);
			this.fetchThreads(
				inputs.fromDate,
				inputs.toDate,
				inputs.search,
				inputs.open,
				inputs.broadcast,
			);
		}
	}

	private queryInputs() {
		const fromDateInput: DateInputElement | null =
			this.shadowRoot?.querySelector("#from") ?? null;

		if (fromDateInput === null) {
			console.error("`fromDateInput` is null");
			return;
		}

		const toDateInput: DateInputElement | null =
			this.shadowRoot?.querySelector("#to") ?? null;

		if (toDateInput === null) {
			console.error("`toDateInput` is null");
			return;
		}

		const searchInput: TextInputElement | null =
			this.shadowRoot?.querySelector("#search") ?? null;

		if (searchInput === null) {
			console.error("`searchInput` is null");
			return;
		}

		const openCheckbox: HTMLInputElement | null =
			this.shadowRoot?.querySelector("#open") ?? null;

		if (openCheckbox === null) {
			console.error("`openCheckbox` is null");
			return;
		}

		const broadcastCheckbox: HTMLInputElement | null =
			this.shadowRoot?.querySelector("#broadcast") ?? null;

		if (broadcastCheckbox === null) {
			console.error("`broadcastCheckbox` is null");
			return;
		}

		return {
			fromDate: fromDateInput.value ?? "",
			toDate: toDateInput.value ?? "",
			search: searchInput.value ?? "",
			open: openCheckbox.checked,
			broadcast: broadcastCheckbox.checked,
		};
	}

	private saveInputs({
		fromDate,
		toDate,
		search,
		open,
		broadcast,
	}: {
		fromDate: string;
		toDate: string;
		search: string;
		open: boolean;
		broadcast: boolean;
	}) {
		localStorage.setItem("mails-fromdate", fromDate);
		localStorage.setItem("mails-todate", toDate);
		localStorage.setItem("mails-search", search);
		localStorage.setItem("mails-open", String(open));
		localStorage.setItem("mails-broadcast", String(broadcast));
	}

	private async fetchThreads(
		fromDate: string,
		toDate: string,
		search: string,
		open: boolean,
		broadcast: boolean,
	) {
		const response = await get<{ data: Thread[] }>(
			`/mails?from=${fromDate}&to=${toDate}&query=${search}&open=${open}&broadcast=${broadcast}`,
		);

		if (response.ok === false) {
			console.error(response.error);
			toast("An error occurred while fetching messages.");
		} else {
			this.threads = response.value.data;
		}
	}
}
