import { API_URL } from "../env.js";
import router from "../router/index.js";

export class APIClient {
	private baseURL: string;

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	set token(v: string) {
		localStorage.setItem("APIToken", v);
	}

	get token(): string {
		return localStorage.getItem("APIToken") ?? "";
	}

	get(endpoint: string, raw = false) {
		const options = {
			method: "GET",
			headers: {
				"fundmarket-token": this.token,
			},
		};

		return this.request(endpoint, options, raw);
	}

	post(endpoint: string, item: object) {
		const options = {
			method: "POST",
			headers: {
				"content-type": "application/json",
				"fundmarket-token": this.token,
			},
			body: JSON.stringify(item),
		};

		return this.request(endpoint, options);
	}

	post_iso(endpoint: string, item: object) {
		const options = {
			method: "POST",
			headers: {
				"content-type": "application/json, charset=iso-8859-1",
				"fundmarket-token": this.token,
			},
			body: JSON.stringify(item),
		};

		return this.request(endpoint, options);
	}

	put(endpoint: string, item: object) {
		const options = {
			method: "PUT",
			headers: {
				"content-type": "application/json",
				"fundmarket-token": this.token,
			},
			body: JSON.stringify(item),
		};

		return this.request(endpoint, options);
	}

	delete(endpoint: string) {
		const options = {
			method: "DELETE",
			headers: {
				"fundmarket-token": this.token,
			},
		};

		return this.request(endpoint, options);
	}

	fetch(endpoint: string, options: RequestInit) {
		const init = Object.assign(
			{
				method: "GET",
				headers: {
					"content-type": "application/octet-stream",
					"fundmarket-token": this.token,
				},
			},
			options,
		);

		return fetch(this.baseURL + endpoint, init).catch(this.handleError);
	}

	request(endpoint: string, options: RequestInit, raw = false) {
		if (raw) {
			return fetch(this.baseURL + endpoint, options)
				.then(this.handleRawResponse)
				.catch(this.handleError);
		}

		return fetch(this.baseURL + endpoint, options)
			.then(this.handleResponse)
			.catch(this.handleError);
	}

	handleResponse(response: Response) {
		if (!response.ok) {
			return Promise.reject(response);
		}

		return response.json().then((res) => res.data || res);
	}

	handleRawResponse(response: Response) {
		if (!response.ok) {
			return Promise.reject(response);
		}

		return response;
	}

	handleError(response: unknown) {
		const url = decodeURIComponent(location.pathname);
		if (
			typeof response === "object" &&
			response !== null &&
			"status" in response &&
			response.status === 401 &&
			url !== "/login"
		) {
			router.push(`/login?redirect=${url}`);
		}
		return Promise.reject(response);
	}
}

const API = new APIClient(API_URL);
window["API"] = API;
export default API;
