import { LitElement } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { ext_render } from "./aml-answers-render";
import { toast } from "../../utils.js";

export default class AMLAnswersView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			ask_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		let todate = localStorage.getItem("ans-todate");
		const fromdate = localStorage.getItem("ans-fromdate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ans-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ans-fromdate", todate);
		}
		this.qquery = localStorage.getItem("ans-qquery");
		this.iquery = localStorage.getItem("ans-iquery");
		this.answers = [];
		this.answeractions = [];
	}

	render() {
		return ext_render(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("ans-todate");
		const fromdate = localStorage.getItem("ans-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ans-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ans-fromdate", todate);
		}
		this.qquery = localStorage.getItem("ans-qquery");
		this.iquery = localStorage.getItem("ans-iquery");

		const urlParams = new URLSearchParams(window.location.search);
		const askId = urlParams.get("ask_id");
		if (askId) {
			await this.refresh(askId);
		}
	}

	async refresh(askId) {
		// If ask_id is present in URL, fetch answers for that specific ask_id
		if (Number.parseInt(askId)) {
			this.ask_id = Number.parseInt(askId);

			try {
				this.rows = await api.get(`/aml/answers?ask_id=${askId}`);
				await this.getAnswers();
			} catch (err) {
				Sentry.captureException(err);
				toast("Det lykkedes ikke at hente data");
			}

			return;
		}

		this.ask_id = undefined;

		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#regdate").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		this.answers = [];
		this.answeractions = [];
		localStorage.setItem("ans-fromdate", dates.from);
		localStorage.setItem("ans-todate", dates.to);
		localStorage.setItem("ans-qquery", parms.qquery);
		localStorage.setItem("ans-iquery", parms.iquery);

		this.iquery = parms.iquery;
		this.qquery = parms.qquery;
		const q1 = `?from=${dates.from}&to=${dates.to}&qquery=${this.qquery}&iquery=${this.iquery}`;

		try {
			this.rows = await api.get(`/aml/answers${q1}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente data");
		}

		submit_button.loading = false;
	}

	async onShowDetails(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.ask_id = id;

		await this.getAnswers();
	}

	async getAnswers() {
		this.answers = [];
		this.answeractions = [];

		this.answers = await api.get(`/answers/ask/${this.ask_id}`);

		const answeractions = await api.get("/lov/answeractions");
		this.answeractions = answeractions;

		await this.requestUpdate();
	}

	async onFileClick(event) {
		event.preventDefault();
		const target = event.target;
		const answerFileId = target.getAttribute("data-id");
		const answerFileName = target.getAttribute("data-name");

		if (answerFileId === "") {
			return;
		}

		try {
			const answerFile = await api.get(`/answers/file/${answerFileId}`);
			const base64Content = answerFile.txtcontent;

			const a = document.createElement("a");
			a.setAttribute("href", base64Content);
			a.setAttribute("download", answerFileName);
			a.click();
		} catch (err) {
			Sentry.captureException(err);
			toast("Der skete en fejl ifm. handling");
			console.log(err);
		}
	}

	async onSaveAnswerAction(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const answerActionId =
			this.shadowRoot.querySelector("#answer-actions").value;
		const answerActionComment = this.shadowRoot.querySelector(
			"#answer-actions-comment",
		).value;

		if (answerActionId === "") {
			return;
		}

		try {
			target.disabled = true;

			const data = {
				type_id: Number.parseInt(answerActionId),
				adm_comment: answerActionComment,
			};
			await api.post(`/answers/action/${id}`, data);

			// reset values
			this.shadowRoot.querySelector("#answer-actions").selectedIndex = 0;
			this.shadowRoot.querySelector("#answer-actions-comment").value = "";
			// refresh answers to currently expanded ask_id
			await this.getAnswers();
			toast("Handling gemt");
		} catch (err) {
			Sentry.captureException(err);
			toast("Der skete en fejl ifm. handling");
			console.log(err);
		} finally {
			target.disabled = false;
		}
	}
}

customElements.define("aml-answers-view", AMLAnswersView);
