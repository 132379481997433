import { html } from "lit";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

export function ext_render(obj) {
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Import PEP data</h1>
          <fm-button id="upd" class="btn" @click="${obj.onPEPUpdate}" >Update from BIQ</fm-button>
        </div>
        <fm-form id="parms" class="reportform no-print" @submit="${obj.refresh}">
        <div class="form-space">
            <label>Search</label>
            <input type="search" class="search-input" name="query" placeholder="Search">
        </div>
        <div class="form-space">
         <label># of months since last update</label>
          <input type="text" name="months">
        </div>
        <div class="form-space">
       <label>Only PEP-related users
         <input type="checkbox" name="pep" />
       </label>
       <label>Include irrelevant PEP-data
         <input type="checkbox" name="irrelevant" />
       </label>

     </div>
        <div class="card-block">
          <fm-button type="submit" id="submit_button" class="btn" >Refresh</fm-button>
        </div>
        </fm-form>
        <div class="card-block">
          ${obj.status_message}
        </div>
        <table>
            <thead>
                <th>Name</th>
                <th>Birthdate</th>
                <th>Approved</th>
                <th>Custodies</th>
                <th>Address</th>
                <th>Country</th>
            </thead>
            <tbody>
          ${obj.rows.map(
						(r) => html`
          <tr>
            <td style="border:1px solid black;border-right:none;"><b>${r.name}</b></td>
            <td style="border-top:1px solid black;border-bottom:1px solid black">${
							r.birthdate
						}</td>
            <td style="border-top:1px solid black;border-bottom:1px solid black">${
							r.approved_yn
						}</td>
            <td style="border-top:1px solid black;border-bottom:1px solid black">${
							r.custody_count
						}</td>
            <td style="border-top:1px solid black;border-bottom:1px solid black">${
							r.address1
						} ${r.address2} ${r.zipcity}</td>
            <td style="border:1px solid black;border-left:none;">${r.country} ${
							r.country_name
						}</td>
           </tr>
           <tr>
            <td colspan="3"></td>
            <td colspan="3">${render_event(r.id, obj)}</td>
           </tr>
           <tr>
             <td colspan="2" style="vertical-align:top;border:2px solid black;">${render_x(
								r.id,
								r.x_pepdata || [],
								obj,
							)}</td>
             <td colspan="2" style="vertical-align:top;border:1px solid black;">${render_q(
								r.u_pepanswer || [],
							)}</td>
             <td colspan="2" style="vertical-align:top;border:1px solid black;">${render_i(
								r.i_pepdata || [],
							)}</td>
           </tr>
          `,
					)}
        </tbody>
        </table>
      </div>
    `;
}

function render_x(user_id, info, obj) {
	return html`
  <table>
  <tr>
  <th colspan="4">External info</th>
  </tr>
  ${info.map(
		(r) => html`
      <tr>
      <td>${r.uuid}</td>
      <td><button type="button" data-uuid="${
				r.uuid
			}" data-id="${user_id}" @click="${obj.uuidDetails}">Import relations</button></td>
      <td>${r.birthdate}</td>
      <td>${r.name}<br>${r.streetname} ${r.housenumber} ${r.door} <br>${
				r.zipcode
			}</td>
      <td>${r.relevant_yn}</td>
      <td><button type="button" data-id="${r.id}" @click="${obj.relevanceChange}">Change</button></td>
      </tr>
      <tr>
      <td colspan="6">${render_x_details(r.details || [])}</td>
      </tr>
      <tr>
      <td colspan="6">${render_x_relations(r.relations || [])}</td>
      </tr>

    `,
	)}
  </table>
 `;
}

function render_x_relations(r) {
	return html`
  <table>
  <tr>
    <th>Relation UUID</th>
    <th>Name</th>
    <th>Type</th>
    <th>Direction</th>
 </tr>
  ${r.map(
		(r) => html`
    <tr>
    <td>${r.relation_uuid}</td>
    <td>${r.relation_name}</td>
    <td>${r.relation_type}</td>
    <td>${r.relation_direction}</td>
    </tr>
  `,
	)}
  </table>`;
}

function render_q(info) {
	return html`
  <table>
  <tr>
  <th colspan="5">Answers</th>
  </tr>
  ${info.map(
		(r) => html`
      <tr>
      <td>${r.ask_date}</td>
      <td>${r.answer_date}</td>
      <td>${r.deleted_yn}</td>
      <td>${r.answer}</td>
      <td>${r.answer_other}</td>
      </tr>
    `,
	)}
  </table>
 `;
}

function render_x_details(info) {
	return html`
  <table>
  <tr>
  <th colspan="4">Details</th>
  </tr>
  ${info.map(
		(r) => html`
      <tr>
      <td>${r.code}</td>
      <td>${r.category}</td>
      <td>${r.startdate}</td>
      <td>${r.enddate}</td>
      </tr>
    `,
	)}
  </table>
 `;
}

function render_i(info) {
	return html`
  <table>
  <tr>
  <th colspan="4">Internal info</th>
  </tr>
  ${info.map(
		(r) => html`
      <tr>
      <td>${r.reg_date}</td>
      <td>${r.code}</td>
      <td>${r.admin_comment}</td>
      <td>${r.canceled}</td>
      </tr>
    `,
	)}
  </table>
 `;
}

function render_event(user_id, obj) {
	return html`
      <fm-form id="eventform" class="form-grid">
        <input type="hidden" name="user_id" value="${user_id}">
          <label>Type
            <select name="type_id">
               ${obj.eventtypes.map(
									(t) =>
										html`<option value="${t.id}">${t.description}</option>`,
								)}
            </select>
          </label>
          <label>Comment
             <input type="text" name="admin_comment">
          </label>
          <fm-button style="max-width:80px;" type="submit" @click="${
						obj.eventSave
					}">Save</fm-button>
       </fm-form>
    `;
}
