import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

import { toast } from "../../utils.js";

export default class AMLSectorView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.dateStr = "";
		this.onError = this.onError.bind(this);
		this.onResponse = this.onResponse.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		const dt = new Date();
		this.dateStr = dt.toLocaleString();
		this.rows = await api.get("/aml/sectors");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Sectors ${this.dateStr}</h2>
        </div>
        <table>
          <thead>
            <tr>
            <th>Code</th>
             <th>Name</th>
             <th>High Risk (startdate/enddate)</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.code}</td>
                <td>${row.description}</td>
                ${this.renderSector(row.id, row.highrisk)}
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderRiskForm(r) {
		return html`
  <tr>
    <td>
      <fm-form  method="put" url="/aml/sectorrisk" @error="${this.onError}" @response="${this.onResponse}">
        <input type="hidden" name="id" value="${r.id}">
        <input type="date" name="startdate" value="${r.startdate}">
        <input type="date" name="enddate" value="${r.enddate}">
        <button type="submit">Update</button>
        <button data-id="${r.id}" type="button" @click="${this.onDelete}" >Delete</button>
      </fm-form>
    </td>
</tr>
  `;
	}

	renderNewRiskForm(id) {
		return html`
  <tr>
    <td>
      <fm-form  method="post" url="/aml/Sectorrisk"  @error="${this.onError}" @response="${this.onResponse}">
        <input type="hidden" name="sector_id" value="${id}">
        <input type="date" name="startdate" required>
        <input type="date" name="enddate" >
        <button type="submit">Insert</button>
      </fm-form>
    </td>
</tr>`;
	}

	renderSector(id, risk) {
		return html`
      <td>
        <table>
          ${this.renderNewRiskForm(id)}
          ${(risk || []).map((r) => this.renderRiskForm(r))}
        </table>
     </td>`;
	}

	onError(_event) {
		toast("Det lykkedes ikke at gemme");
	}

	async onResponse(_event) {
		toast("Gemt");
		this.rows = await api.get("/aml/sectors");
	}

	async onDelete(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");

		try {
			await api.delete(`/aml/sectorrisk/${id}`);
			toast("Slettet");
			this.rows = await api.get("/aml/sectors");
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at slette");
		}
	}
}

customElements.define("aml-sector-view", AMLSectorView);
