import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"funds-calendars-view": FundsCalendarsView;
	}
}

@customElement("funds-calendars-view")
export class FundsCalendarsView extends LitElement {
	@state()
	private funds: { id: number; name: string }[] = [];

	@state()
	private fundCalendar: {
		id: number;
		reg_date: string;
		valuedate: string;
		cutoffdate: string;
		tradedate: string;
	}[] = [];

	@state()
	private holidays: { id: number; date: string }[] = [];

	@state()
	private holidayDate = "";

	@state()
	private fundId: number | undefined;

	@state()
	private valueDate = "";

	@state()
	private cutoffDate = "";

	@state()
	private tradeDate = "";

	async connectedCallback() {
		super.connectedCallback();
		this.funds = await api.get("/lov/funds?openclose=C");
		await this.loadHolidays();
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`:host {
			display: block;
			margin: 24px auto;
			max-width: 1400px;
			}
			.tiny-button {
			  min-width: 50px;
			  font-size:10px;
			  padding: 4px;
			}
		
			.delete {
			  background-color: red;
			}`,
	];

	render() {
		return html`
    <div class="card">
      <div class="card-header">
        <h1>Calendars</h1>
        
      </div>
      <div class="card-header" style="justify-content: initial;">
		<select @change="${this.onCalendarChange}">
		<option value="holidays">Holidays</option>
		${this.funds.map((f) => html`<option value="${f.id}">${f.name}</option>`)}
		</select>
      </div>
      <div class="card-block">
        ${
					this.fundId === undefined
						? html`${this.renderHolidays()}`
						: html`${this.renderFundCalendar()}`
				}
      </div>
    </div>
  `;
	}

	renderHolidays() {
		let year = 0;
		const holidays: Array<ReturnType<typeof html>> = [];

		for (const holiday of this.holidays) {
			const holidayDate = new Date(holiday.date);
			const holidayYear = holidayDate.getFullYear();
			const humanDate = new Intl.DateTimeFormat("da-dk", {
				dateStyle: "full",
			});

			if (year !== holidayYear) {
				year = holidayYear;
				holidays.push(html`<tr><th colspan="2">${holidayYear}</th></tr>`);
			}
			holidays.push(html`
				<tr>
					<td>${humanDate.format(holidayDate)}</td>
					<td><fm-button-v2 type="button" class="button delete tiny-button" data-id="${
						holiday.id
					}" data-date="${holiday.date}" @click="${this.removeHoliday}">Remove</fm-button-v2></td>
				</tr>`);
		}

		return html`
			<form id="holidays-form">
				<table>
				<tr>
					<td valign="bottom">
						<label class="form-field">Holiday
							<input type="date" class="date-input" id="holiday-date" .value="${
								this.holidayDate
							}" @change=${(e) => {
								this.holidayDate = e.target.value;
							}} />
						</label>
					</td>
					<td>
						<label class="form-field">&nbsp;<fm-button-v2 type="button" class="btn--small" @click="${
							this.addHoliday
						}">Add</fm-button-v2></label>
					</td>
				</tr>
				${holidays}
				</table>
			</form>`;
	}

	renderFundCalendar() {
		let year = 0;
		const dates: Array<ReturnType<typeof html>> = [];

		for (const calendarDate of this.fundCalendar) {
			const valueDate = new Date(calendarDate.valuedate);
			const cutoffDate = new Date(calendarDate.cutoffdate);
			const tradeDate = new Date(calendarDate.tradedate);
			const valueDateYear = valueDate.getFullYear();
			const humanDate = new Intl.DateTimeFormat("da-dk", {
				dateStyle: "full",
			});

			if (year !== valueDateYear) {
				year = valueDateYear;
				dates.push(html`
				<tr>
					<th colspan="4">${valueDateYear}</th>
				</tr>
				<tr>
					<th>Value Date</th>
					<th>Cut-Off Date</th>
					<th>Trade Date</th>
					<th></th>
				</tr>`);
			}
			dates.push(html`
				<tr>
					<td>${humanDate.format(valueDate)}</td>
					<td>${humanDate.format(cutoffDate)}</td>
					<td>${humanDate.format(tradeDate)}</td>
					<td><fm-button-v2 type="button" class="button delete tiny-button" data-id="${
						calendarDate.id
					}" data-value-date="${calendarDate.valuedate}" @click="${
						this.removeCalendarDate
					}">Remove</fm-button-v2></td>
				</tr>`);
		}

		return html`
			<form id="fund-calendar-form">
				<table>
				<tr>
					<td style="display: flex;" colspan="2"><label class="form-field">Value Date<input type="date" class="date-input" id="fund-value-date"  .value="${
						this.valueDate
					}" @change=${(e) => {
						this.valueDate = e.target.value;
					}} /></label></td>
					<td><label class="form-field">Cut-Off Date<input type="date" class="date-input" id="fund-cutoff-date"  .value="${
						this.cutoffDate
					}" @change=${(e) => {
						this.cutoffDate = e.target.value;
					}} /></label></td>
					<td><label class="form-field">Trade Date<input type="date" class="date-input" id="fund-trade-date"  .value="${
						this.tradeDate
					}" @change=${(e) => {
						this.tradeDate = e.target.value;
					}} /></label></td>
					<td><label class="form-field">&nbsp;<fm-button-v2 type="button" class="btn--small" @click="${
						this.addCalendarDate
					}">Add</fm-button-v2></label></td>
				</tr>
				${dates}
				</table>
			</form>`;
	}

	async onCalendarChange(event) {
		const target = event.target;

		if (target.value === "holidays") {
			await this.loadHolidays();
			this.fundId = undefined;
		} else {
			await this.loadFundCalendar(target.value);
			this.fundId = Number.parseInt(target.value);
		}
	}

	async loadHolidays() {
		this.holidays = await api.get("/funds/holidays");
	}

	async addHoliday(_event) {
		if (!this.holidayDate) {
			toast("Invalid date");
			return;
		}

		try {
			await api.post("/funds/holidays", {
				date: this.holidayDate,
			});
			toast(`${this.holidayDate} added`);

			this.holidayDate = "";

			await this.loadHolidays();
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
	}

	async removeHoliday(event) {
		const id = event.target.dataset.id;
		const holiday = event.target.dataset.date;

		if (!confirm(`Remove the holiday on ${holiday}?`)) {
			return;
		}

		try {
			await api.delete(`/funds/holidays/${id}`);
			toast(`${holiday} removed`);

			await this.loadHolidays();
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
	}

	async loadFundCalendar(id) {
		this.fundId = id;
		this.fundCalendar = await api.get(`/funds/${this.fundId}/calendar`);
	}

	async addCalendarDate(_event) {
		if (!this.valueDate) {
			toast("Invalid value date");
			return;
		}
		if (!this.cutoffDate) {
			toast("Invalid cut-off date");
			return;
		}
		if (!this.tradeDate) {
			toast("Invalid trade date");
			return;
		}

		try {
			await api.post(`/funds/${this.fundId}/calendar`, {
				valuedate: this.valueDate,
				cutoffdate: this.cutoffDate,
				tradedate: this.tradeDate,
			});
			toast(`${this.valueDate} added`);

			this.valueDate = "";
			this.cutoffDate = "";
			this.tradeDate = "";

			await this.loadFundCalendar(this.fundId);
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}

		return;
	}

	async removeCalendarDate(event) {
		const id = event.target.dataset.id;
		const valueDate = event.target.dataset.valueDate;

		if (!confirm(`Remove value date ${valueDate}?`)) {
			return;
		}

		try {
			await api.delete(`/funds/${this.fundId}/calendar/${id}`);
			toast(`Value date ${valueDate} removed`);

			await this.loadFundCalendar(this.fundId);
		} catch (err) {
			Sentry.captureException(err);
			const errmsg = await (err as Response).json();
			toast(JSON.stringify(errmsg));
		}
	}
}
