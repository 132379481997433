import FormcalcView from "./formcalc-view.js";
import FormcalcListView from "./formcalc-list-view.js";
import FormcalcEditView from "./formcalc-edit-view.js";
import FormcalcNewView from "./formcalc-new-view.js";
import FormCalculationsView from "./formcalculations-view.js";
import FormcalcShowView from "./formcalc-show-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "formcalc",
	component: FormcalcView,
	children: [
		{
			path: "",
			component: FormcalcListView,
		},
		{
			path: "new",
			component: FormcalcNewView,
		},
		{
			path: "calculations",
			component: FormCalculationsView,
			children: [
				{
					path: ":id",
					component: FormcalcShowView,
					properties: ({ parameters }) => ({
						calcid: parameters.get("id"),
					}),
				},
			],
		},
		{
			path: ":id",
			component: FormcalcEditView,
			properties: ({ parameters }) => ({
				form_id: parameters.get("id"),
			}),
		},
	],
} satisfies RouteOptions;
