import { LitElement } from "lit";
import api from "../../api/index.js";
import { toast } from "../../utils.js";
import { renderAMLJobs } from "./aml-jobs-render.js";

export default class AMLJobsView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			evaluationtypes: {
				type: Array,
			},
			jobtypes: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		this.onEvaluationSave = this.onEvaluationSave.bind(this);
		this.breachtypes = [];
		this.evaluationtypes = [];
	}

	async connectedCallback() {
		super.connectedCallback();
		this.breaches = localStorage.getItem("amljob-breaches") || "";
		this.evaluations = localStorage.getItem("amljob-evaluations") || "";
		this.breachtypes = await api.get(`/aml/breachtypes?query=${this.breaches}`);
		this.evaluationtypes = await api.get(
			`/aml/evaluationtypes?query=${this.evaluations}`,
		);
	}

	render() {
		return renderAMLJobs(this);
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const button = this.shadowRoot.querySelector("#submit_button");
		const breach_types = parms.breachtypes.join(",");
		localStorage.setItem("amljob-breaches", breach_types);
		const evaluation_types = parms.evaluationtypes.join(",");
		localStorage.setItem("amljob-evaluations", evaluation_types);
		button.loading = true;
		try {
			const q = `/aml/breach?breach_types=${breach_types}&evaluation_types=${evaluation_types}&query=${parms.query}`;
			this.rows = await api.get(q);
		} catch {
			toast("Fetch failed");
			this.rows = [];
		}
		button.loading = false;
	}

	async onEvaluationSave(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const evaluation_type_id = this.shadowRoot.querySelector(
			`#select-${id}`,
		).value;
		const adm_comment = this.shadowRoot.querySelector(`#cmt-${id}`).value;
		try {
			await api.post(`/aml/breach/${id}/evaluation`, {
				evaluation_type_id,
				adm_comment,
			});
			await this.refresh();
		} catch (err) {
			toast(JSON.stringify(err));
		}
	}
}

customElements.define("aml-jobs-view", AMLJobsView);
