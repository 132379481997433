import { LitElement, html } from "lit";

class OptionElement extends LitElement {
	static get properties() {
		return {
			value: {
				type: Object,
				reflect: true,
				converter: {
					toAttribute(val) {
						const retVal = val;
						return retVal;
					},

					fromAttribute(val) {
						let retVal;
						try {
							retVal = JSON.parse(val);
						} catch {
							retVal = val;
						}
						return retVal;
					},
				},
			},
		};
	}

	constructor() {
		super();
		this.onMousedown = this.onMousedown.bind(this);
	}

	render() {
		return html`
      <style>
        :host {
          cursor: pointer;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 32px;
          padding: 0 8px;
          font-size: 14px;
          background: white;
        }

        :host([selected]),
        :host(:hover) {
          background: #2979FF;
          color: white;        
        }
      </style>
      <slot></slot>
    `;
	}

	connectedCallback() {
		super.connectedCallback();
		this.setAttribute("role", "option");
		this.addEventListener("mousedown", this.onMousedown);
	}

	onMousedown() {
		this.dispatchEvent(new Event("select"));
	}

	select() {
		this.selected = true;
		this.setAttribute("selected", "");
		this.setAttribute("aria-selected", "true");
	}

	deselect() {
		this.selected = false;
		this.removeAttribute("selected");
		this.setAttribute("aria-selected", "false");
	}
}

customElements.define("fm-option", OptionElement);
